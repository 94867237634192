<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">충전일자</span>
            <div class="d-flex" style="flex: 4">
              <b-form-input 
                size="sm"
                style="flex: 5"
                type="date"
                v-model="filterData.searchStartDate"
                @change="changeSearchDate"
              ></b-form-input>
              <span style="margin-left: 10px; margin-right: 10px; flex: 1">~</span>
              <b-form-input 
                size="sm"
                style="flex: 5"
                type="date"
                v-model="filterData.searchEndDate"
                @change="changeSearchDate"
              ></b-form-input>
            </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">기관명</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchCompany" @change="filterSet" :options="searchCompanyOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="filterSet"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end">
          <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: inherit;" >
          <b-table 
            id = "grid"
            bordered hover noCollapse
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(bid)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].bid }}
              </div>
            </template>
            <template #cell(bid_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].bid_name }}
              </div>
            </template>
            <template #cell(sid)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].sid }}
              </div>
            </template>
            <template #cell(cid)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].cid }}
              </div>
            </template>
            <template #cell(tsdt)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].tsdt }}
              </div>
            </template>
            <template #cell(tedt)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].tedt }}
              </div>
            </template>
            <template #cell(pow)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].pow }}
              </div>
            </template>
            <template #cell(mon)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].mon }}
              </div>
            </template>
            <template #cell(regdate)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].regdate }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    //ModalExcelUpload: Modals.ExcelUpload,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        bid : "",
        bid_name : "",
        no : "",
        stop : "",
        stop_text : "",
        regdate : "",
        stop_date : "",
        upddate : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "bid",
          label: "기관코드"
        },
        {
          key: "bid_name",
          label: "기관명"
        },
        {
          key: "sid",
          label: "충전소ID"
        },
        {
          key: "cid",
          label: "충전기ID"
        },
        {
          key: "tsdt",
          label: "충전<br/>시작시간"
        },
        {
          key: "tedt",
          label: "충전<br/>종료시간"
        },
        {
          key: "pow",
          label: "충전량"
        },
        {
          key: "mon",
          label: "충전금액"
        },
        {
          key: "regdate",
          label: "등록일"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchStartDate: "",
        searchEndDate: "",
        searchCompany: "",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소ID", value: "sid" },
        { text: "충전기ID", value: "cid" }
      ],
      searchCompanyOption: [],
      file_type: "roming_charging_history",
      rules: {
        
      },
      excelUp_show: false,
      tranHistory_show: false,
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectRomingUseList() {
      console.log("로밍 충전기 사용내역 페이지");
      let s_date_str = this.filterData.searchStartDate;
        let e_date_str = this.filterData.searchEndDate;

      try {
        const { data } = await this.$axios.post(
          "/api/selectRomingUseList", {
            tsdt : s_date_str.replaceAll("-", ""),
            tedt : e_date_str.replaceAll("-", ""),
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;

          let temp_data = data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.bid_name === e.bid_name
              )
          );

          let options = [{ value: "all", text: "전체" }];

          temp_data.forEach((e,i) => {
            let add_option = { value: e.bid, text: e.bid_name }
            options.push(add_option);
          });

          this.searchCompanyOption = options;
          this.filterData.searchCompany = "all";

          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "roming_charging_history_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'roming_charging_history_'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_company = this.filterData.searchCompany;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1 || e.cid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "cid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.cid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_company != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.bid === search_company) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          
          this.select_rowData = {};

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 130;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    changeSearchDate() {
      this.selectRomingUseList();
    }
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let crt_dateTime = this.getToday();
    let crt_date = crt_dateTime.substring(0, 4) + "-" + crt_dateTime.substring(4, 6) + "-" + crt_dateTime.substring(6, 8);
    this.filterData.searchStartDate = crt_date;
    this.filterData.searchEndDate = crt_date;

    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;

    this.selectRomingUseList();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>