<template>
  <b-modal
    id="modal-charger-detail"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="xl"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '1600px', maxHeight: '1000px' }"
    >
      <div>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />충전소 이름
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.sid" :options="snameOptions" @change="changeStation($event)"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />설치 유입경로
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.i_funnel" :options="iFunnelOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />소유 회사
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.owner" :options="bnameOptions"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />충전기 모델
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input style="flex: 7; height: 50px; border-radius: 10px;" v-model="chargerData.model_id"></b-form-input>
                <b-button style="flex: 3;" class="btn_def" @click="searchModel">검색</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />급속/완속
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.laf" :options="lafOptions" @change="changeLaf($event)"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />사용 유무
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.use_yn" :options="useYnOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />충전건 타입
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.type" :options="typeOptions"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />P / N
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.pn_id" :options="pnOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />제조사
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.maker" :options="makerOptions"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />충전 요금
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <!-- 라디오 버튼 -->
                <b-form-group class="radio-group-sqaure2" style="margin: 0px;">
                  <b-form-radio-group
                    :options="payOption"
                    v-model="chargerData.pay"
                    buttons
                    button-variant="light-gray"
                  />
                </b-form-group>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />단말기 ID
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input style="flex: 7; height: 50px; border-radius: 10px;" v-model="chargerData.reader_id"></b-form-input>
                <b-button style="flex: 3;" class="btn_def" @click="searchReader">검색</b-button>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />충전기 설비용량(kW)
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2; gap: 10px;">
                <b-form-select style="flex: 5;" size="sm" v-model="chargerData.c_capacity" :options="capacityOptions"></b-form-select>
                <b-form-select style="flex: 5;" size="sm" v-model="channel_count" :options="channelOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />펌웨어 버전
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input style="height: 50px; border-radius: 10px;" v-model="chargerData.firmware_ver"></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />오디오 버전
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input style="height: 50px; border-radius: 10px;" v-model="chargerData.audio_ver"></b-form-input>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />결제 단말기
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.p_terminal" :options="pTerminalOptions" @change="changePTerminal($event)"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />수전 방식
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.r_method" :options="methodOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />MID
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input disable style="height: 50px; border-radius: 10px;" v-model="chargerData.mid"></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />AS 관리주체
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.as_id" :options="asOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />TERMINAL ID
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input disable style="height: 50px; border-radius: 10px;" v-model="chargerData.tem_id"></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />계약 업체
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.sign" :options="signOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />설치 일자
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input 
                  style="height: 50px; border-radius: 10px;"
                  type="date"
                  v-model="install_date"
                  @change="changeInstallDate"
                ></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />상태 정보
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.status" :options="statusOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />보조금 구분
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.subsidy" :options="subsidyOptions"></b-form-select>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />설치 업체
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="chargerData.setup" :options="setupOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row style="display: flex; margin: 0px 0px 10px 0px; height: 65px; align-items: center;">
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />보조금 금액
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-input v-bind:disable="chargerData.subsidy === 'N'" type=number style="height: 50px; border-radius: 10px;" v-model="chargerData.subsidy_amount"></b-form-input>
              </div>
            </div>
          </b-col>
          <b-col lg="6">
            <div class="modal-input-row">
              <div class="modal-input-title">
                <span class="text-17">
                  <font-awesome-icon />설치 타입
                </span>
              </div>
              <div class="modal-input-content" style="flex: 2;">
                <b-form-select size="sm" v-model="install_type" :options="installTypeOptions"></b-form-select>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="modal-input-row" style="height: 150px; margin-left: 15px; margin-right: 15px;">
          <div class="modal-input-title" style="height: 95%;">
            <span class="text-17">
              <font-awesome-icon />메모
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-textarea
              id="resultText"
              v-model="chargerData.note"
              placeholder=""
              size="sm"
              style="border-radius: 10px;"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>

    <!-- modals -->
    <modal-reader-list ref="modal_readerList" :obj="obj_reader" @input="selectReader"/>
    <modal-model-list ref="modal_modelList" :obj="obj_model" @input2="selectModel" />
  </b-modal>
</template>

<script>
import { disable } from 'ol/rotationconstraint';

import ModalReaderList from "./ReaderList.vue";
import ModalModelList from "./ModelList.vue";


export default {
  components: {
    ModalReaderList,
    ModalModelList,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      charger_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      obj_reader: {
        sid : ""
      },
      obj_model: {
        maker : ""
      },
      admin_data: {},
      chargerData: {
        sid : "",
        s_name : "",
        cid : "",
        reader_id : "",
        bid : "",
        b_name : "",
        type : "",
        member : "",
        laf : "F",
        laf_dtl : "",
        pay : "Y",
        cable : "",
        stat_dt : "",
        expired_dt : "",
        note : "",
        year : 0,
        month : 0,
        maker : "",
        maker_name : "",
        owner : "",
        sign : "",
        setup : "",
        subsidy : "N",
        subsidy_amount : "",
        motie : "",
        limit_yn : "",
        limit_dtl : "",
        del_yn : "",
        del_dtl : "",
        reg_date : "",
        reg_user : "",
        update_date : "",
        update_user : "",
        c_channel : "",
        p_terminal : "N",
        i_funnel : "1",
        use_yn : "Y",
        failure_yn : "",
        r_type : "",
        c_capacity : "",
        audio_ver : "",
        c_standard : "",
        firmware_ver : "",
        pn_id : "",
        r_method : "",
        as_id : "",
        as_name : "",
        tr_yn : "",
        ocpp_yn : "",
        kakao_yn : "",
        tmap_yn : "",
        descript : "",
        zcode : "",
        zsgcode : "",
        laf_name : "",
        status : "Available",
        status_name : "",
        bis_id : "",
        mid : "",
        tem_id : "",
        page_num : "",
        id : 0,
        model_id : "",
        ch_id : 0,
      },
      chargerValidate_data: [],
      stationList_data: [],
      snameOptions: [],
      bnameOptions: [],
      lafOptions: [
        { value: "F", text: "급속" },
        { value: "L", text: "완속" }
      ],
      typeOptions: [],
      makerOptions: [],
      pTerminalOptions: [
        { value: "N", text: "없음" },
        { value: "Y", text: "있음" }
      ],
      install_date: "",
      subsidyOptions: [
        { value: "N", text: "보조금 없음" },
        { value: "E", text: "환경부 보조금" }
      ],
      iFunnelOptions: [
        { value: "1", text: "이브이페이 투자" },
        { value: "2", text: "직접 문의" }
      ],
      useYnOptions: [
        { value: "Y", text: "사용" },
        { value: "N", text: "미사용" }
      ],
      pName_data: [],
      pnOptions: [],
      capacityOptions: [],
      methodOptions: [],
      asOptions: [],
      signOptions: [],
      statusOptions: [
        { value: "Available", text: "사용 가능" },
        { value: "Preparing", text: "준비중" },
        { value: "Charging", text: "충전중" },
        { value: "SuspendedEVSE", text: "정지된 EVSE" },
        { value: "SuspendedEV", text: "정지된 EV" },
        { value: "Finishing", text: "충전완료중" },
        { value: "Reserved", text: "예약대기" },
        { value: "Unavailable", text: "존재하지 않음" },
        { value: "Faulted", text: "오류 발생" }
      ],
      setupOptions: [],
      install_type: "",
      installTypeOptions: [],
      dual_yn: "N",
      payOption: [
        { value: "Y", text: "유료" },
        { value: "N", text: "무료" }
      ],
      channel_count: 1,
      channelOptions: [],
      select_iccid: "",
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.admin_data = this.$store.getters.user;
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.selectStationList();
        this.wrap = this.$refs.addr;

        if (this.obj.edit_type === "add") {
          this.chargerData = this.obj.charger_data;
        }
      });
    },
    async selectStationList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectStationList", {
            
          }
        );
        if (data.length > 0) {
          this.statiionList_data = data;
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.sid, text: e.name }
            options.push(add_option);
          });

          this.snameOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.chargerData.sid = this.snameOptions[0].value;
          }
        } else if (data.length == 0) {
          this.snameOptions = null;
        }

        this.selectCode();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectStationList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectStationList", {
            
          }
        );
        if (data.length > 0) {
          this.statiionList_data = data;
          let options = [];

          data.forEach((e,i) => {
            let add_option = { value: e.sid, text: e.name }
            options.push(add_option);
          });

          this.snameOptions = options;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.chargerData.sid = this.snameOptions[0].value;
          }
        } else if (data.length == 0) {
          this.snameOptions = null;
        }

        this.selectEnc();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectEnc() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectEnc", {
            type : "channal_max_count"
          }
        );
        if (data.length > 0) {
          let options = [];

          let value_count = parseInt(data[0].value);

          for (let i = 0; i < value_count; i++) {
            let text_str = (i + 1).toString() + "CH";
            let add_option = { value: i + 1, text: text_str };
            options.push(add_option);
          }

          this.channelOptions = options;
          this.channel_count = 1;
        } else if (data.length == 0) {
          this.channelOptions = { value: i + 1, text: text_str };
        }

        this.channel_count = 1;

        this.selectCode();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCode() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCode", {
            use_yn: "Y", 
            type: "CPC"
          }
        );

        if (data.length > 0) {
          let gun_data = [];
          let capacity_data = [];
          let method_data = [];
          let installType_data = [];
          let pName_data = [];
          
          gun_data = data.filter(
            function (e) {
              if (e.r_code === "CPC01") {
                return true;
              }
            }
          )

          capacity_data = data.filter(
            function (e) {
              if (e.r_code === "CPC02") {
                return true;
              }
            }
          )

          method_data = data.filter(
            function (e) {
              if (e.r_code === "CPC03") {
                return true;
              }
            }
          )

          installType_data = data.filter(
            function (e) {
              if (e.r_code === "CPC04") {
                return true;
              }
            }
          )

          this.pName_data = data.filter(
            function (e) {
              if (e.r_code === "CPC06") {
                return true;
              }
            }
          )

          let options1 = [];
          let options2 = [];
          let options3 = [];
          let options4 = [];
          let options5 = [];

          gun_data.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options1.push(add_option);
          });

          capacity_data.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options2.push(add_option);
          });

          method_data.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options3.push(add_option);
          });

          installType_data.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options4.push(add_option);
          });

          let pName_data2 = [];
          let laf_str = this.chargerData.laf;
          pName_data2 = this.pName_data.filter(
            function (e) {
              if (e.descript === laf_str) {
                return true;
              }
            }
          )

          pName_data2.forEach((e,i) => {
            let add_option = { value: e.code, text: e.code_name }
            options5.push(add_option);
          });

          this.typeOptions = options1;
          this.capacityOptions = options2;
          this.methodOptions = options3;
          this.installTypeOptions = options4;
          this.pnOptions = options5;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.chargerData.type = this.typeOptions[0].value;
            this.chargerData.c_capacity = this.capacityOptions[0].value;
            this.chargerData.r_method = this.methodOptions[0].value;
            this.install_type = this.installTypeOptions[0].value;
            this.chargerData.pn_id = this.pnOptions[0].value;
          }
        } else if (data.length == 0) {
          this.typeOptions = null;
          this.capacityOptions = null;
          this.methodOptions = null;
          this.installTypeOptions = null;
          this.pnOptions = null;
        }

        this.selectCompanyList();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async selectCompanyList() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectCompanyList", {
            use_yn : "Y"
          }
        );
        if (data.length > 0) {
          let companyI_data = [];
          let companyM_data = [];
          let companyS_data = [];
          let companyA_data = [];

          companyI_data = data.filter(
            function (e) {
              if (e.type === "I") {
                return true;
              }
            }
          )

          companyM_data = data.filter(
            function (e) {
              if (e.type === "M") {
                return true;
              }
            }
          )

          companyS_data = data.filter(
            function (e) {
              if (e.type === "S") {
                return true;
              }
            }
          )

          companyA_data = data.filter(
            function (e) {
              if (e.type === "A") {
                return true;
              }
            }
          )

          let options1 = [];
          let options2 = [];
          let options3 = [];
          let options4 = [];

          companyI_data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options1.push(add_option);
          });

          companyM_data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options2.push(add_option);
          });

          companyS_data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options3.push(add_option);
          });

          companyA_data.forEach((e,i) => {
            let add_option = { value: e.company_id, text: e.name }
            options4.push(add_option);
          });

          this.setupOptions = options1;
          this.makerOptions = options2;
          this.bnameOptions = options3;
          this.signOptions = options3;
          this.asOptions = options4;

          if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
            this.chargerData.setup = this.setupOptions[0].value;
            this.chargerData.maker = this.makerOptions[0].value;
            this.chargerData.owner = this.snameOptions[0].value;
            this.chargerData.sign = this.snameOptions[0].value;
            this.chargerData.as_id = this.asOptions[0].value;
          }
        } else if (data.length == 0) {
          this.setupOptions = null;
          this.makerOptions = null;
          this.bnameOptions = null;
          this.signOptions = null;
          this.asOptions = null;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    ok() {
      if (this.obj.edit_type === "detail") {
        this.$bvModal.hide("modal-charger-detail");
      } else {
        if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
          this.selectChargerValidate();

          let crt_date = this.getToday();

          this.chargerData.member = "Y"
          this.chargerData.cable = "N"
          this.chargerData.stat_dt = crt_date
          this.chargerData.expired_dt = crt_date
          this.chargerData.motie = "N"
          this.chargerData.del_yn = "N"
          this.chargerData.del_dtl = ""
          this.chargerData.reg_date = crt_date
          this.chargerData.update_date = crt_date
          this.chargerData.failure_yn = "N"
          this.chargerData.r_type = "N"
          this.chargerData.tr_yn = "N"
          this.chargerData.ocpp_yn = "Y"
          this.chargerData.kakao_yn = "N"
          this.chargerData.tmap_yn = "N"

          this.chargerData.reg_user = this.admin_data.user_id;
          this.chargerData.update_user = this.admin_data.user_id;

          if (this.channel_count == 1) {
            this.addCharger();
          } else if (this.channel_count > 1) {
            this.addChargerList();
          }
        } else if (this.obj.edit_type === "edit") {
          this.updateCharger();
        }
      }
    },
    async addCharger() {
      // 충전기ID 생성
      if (this.chargerValidate_data.length == 0) {
        var charger_no = 1;
      } else {
        var charger_lastNo = parseInt(this.chargerValidate_data[this.chargerValidate_data.length - 1].cid);
        var charger_no = charger_lastNo + 1;
      };
      var max_regNo = '00' + String(charger_no);
      var mod_regNo = max_regNo.slice(-2);
      var charger_Id = mod_regNo;
      this.chargerData.cid = charger_Id;

      let crt_date = this.getToday();

      this.chargerData.laf_dtl = this.chargerData.type;
      this.chargerData.c_channel = this.chargerData.type;
      this.chargerData.c_standard = this.chargerData.type;

      this.chargerData.ch_id = 1;

      var input_data = this.chargerData;
      
      try {
        const { data } = await this.$axios.post("/api/addCharger", input_data);

        this.addChargerStatus();
      } catch (error) {
        window.alert("충전기 정보 등록을 실패했습니다.");
      }
    },
    async addChargerList() {
      this.chargerData.laf_dtl = this.chargerData.type;
      this.chargerData.c_channel = this.chargerData.type;
      this.chargerData.c_standard = this.chargerData.type;

      let crt_date = this.getToday();

      var add_charger_list = [];
      var add_chargerStatus_list = [];
      var add_ocppCharger_list = [];

      // 충전기ID 생성
      var charger_no = 0;
      var log_charger_id = "";
      if (this.chargerValidate_data.length == 0) {
        charger_no = 1;
      } else {
        var charger_lastNo = parseInt(this.chargerValidate_data[this.chargerValidate_data.length - 1].cid);
        charger_no = charger_lastNo + 1;
      };

      for (j=0; j < this.chargerData.channel_count; j++) {
        charger_no = charger_no + j;
        var max_regNo = '00' + String(charger_no);
        var mod_regNo = max_regNo.slice(-2);
        var charger_Id = mod_regNo;
        this.chargerData.cid = charger_Id;
        this.chargerData.ch_id = 1 + j;

        if (log_charger_id == "") {
          log_charger_id = charger_Id;
        } else {
          log_charger_id = log_charger_id + ", " + charger_Id;
        }

        add_list.push(this.chargerData);

        var status_data = {
          sid: this.chargerData.sid,
          cid: this.chargerData.cid,
          reader_id: this.chargerData.reader_id,
          status: "Available",
          cable_status: "N",
          rs_signal: "1",
          c_abnormal: "N",
          b_status: "N",
          card_status: "N",
          u_electricity: 0,
          a_electricity: 0,
          charge: 0,
          a_amount: 0,
          c_speed: 0,
          update_date: this.chargerData.reg_date
        }

        add_chargerStatus_list.push(status_data);

        var ocpp_charger = {
          vendor_id: "drusucoev",
          model: this.chargerData.cid,
          serial: "drusucoev_" + this.chargerData.cid,
          box_sn: this.chargerData.reader_id,
          fw_ver: this.chargerData.firmware_ver,
          iccid: this.select_iccid,
          imsi: this.chargerData.reader_id,
          meter_type: "MT01",
          meter_sn: "M00001",
          ver: this.chargerData.firmware_ver,
          status: "Available",
          sid: this.chargerData.sid,
          cid: this.chargerData.cid,
          tel_num: this.chargerData.reader_id
        }

        add_ocppCharger_list.push(ocpp_charger);
      }

      try {
        const { data } = await this.$axios.post("/api/addChargerList", add_list);
      } catch (error) {
        window.alert("충전기 정보 등록을 실패했습니다.");
      }

      try {
        const { data } = await this.$axios.post("/api/addChargerStatusList", add_chargerStatus_list);
      } catch (error) {
        window.alert("충전기 상태정보 등록을 실패했습니다.");
      }

      try {
        const { data } = await this.$axios.post("/api/addOcppChargerPointList", add_ocppCharger_list);
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }

      window.alert("충전기 등록이 완료되었습니다.");
    	var context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + log_charger_id + ", 단말기번호 : " + this.chargerData.reader_id;
      this.addAdminLog("충전기 관리", "충전기 등록", "충전기 등록팝업", context, this.admin_data.user_id);
      this.$emit("reflash");
      this.$bvModal.hide("modal-charger-detail");
    },
    async updateCharger() {
      let crt_date = this.getToday();

      this.chargerData.update_date = crt_date;

      try {
        const { data } = await this.$axios.post("/api/updateCharger", this.chargerData);

        window.alert("충전기 수정이 완료되었습니다.");
    		var context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
        this.addAdminLog("충전기 관리", "충전기 수정", "충전기 수정팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-charger-detail");
      } catch (error) {
        window.alert("충전기 정보 수정을 실패했습니다.");
      }
    },
    async selectChargerValidate() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerValidate", {
            sid: this.chargerData.sid
          }
        );

        if (data.length > 0) {
          this.chargerValidate_data = data;
        } else if (data.length == 0) {
          this.chargerValidate_data = null;
        }
      } catch (error) {
        window.alert("특정 충전소의 충전기 정보 조회를 실패했습니다.");
      }
    },
    async addChargerStatus() {
      try {
        const { data } = await this.$axios.post(
          "/api/addChargerStatus", {
            sid: this.chargerData.sid,
						cid: this.chargerData.cid,
						reader_id: this.chargerData.reader_id,
						status: "Available",
						cable_status: "N",
						rs_signal: "1",
						c_abnormal: "N",
						b_status: "N",
						card_status: "N",
						u_electricity: 0,
						a_electricity: 0,
						charge: 0,
						a_amount: 0,
						c_speed: 0,
						update_date: this.chargerData.reg_date
          }
        );

        this.addOcppChargerPoint();
      } catch (error) {
        window.alert("충전기 상태정보 등록을 실패했습니다.");
      }
    },
    async addOcppChargerPoint() {
      try {
        const { data } = await this.$axios.post(
          "/api/addOcppChargerPoint", {
            vendor_id: "drusucoev",
						model: this.chargerData.cid,
						serial: "drusucoev_" + this.chargerData.cid,
						box_sn: this.chargerData.reader_id,
						fw_ver: this.chargerData.firmware_ver,
						iccid: this.select_iccid,
						imsi: this.chargerData.reader_id,
						meter_type: "MT01",
						meter_sn: "M00001",
						ver: this.chargerData.firmware_ver,
						status: "Available",
						sid: this.chargerData.sid,
						cid: this.chargerData.cid,
						tel_num: this.chargerData.reader_id
          }
        );

        window.alert("충전기 등록이 완료되었습니다.");
    		var context = "충전소ID : " + this.chargerData.sid + ", 충전기ID : " + this.chargerData.cid + ", 단말기번호 : " + this.chargerData.reader_id;
        this.addAdminLog("충전기 관리", "충전기 등록", "충전기 등록팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-charger-detail");
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
    changeStation (select_sid) {
      let temp_data = this.statiionList_data.filter(
        function (e) {
          if (e.sid === select_sid) {
            return true;
          }
        }
      )

      this.chargerData.owner = temp_data[0].bid;
      this.chargerData.sign = temp_data[0].bid;
    },
    changeLaf (select_laf) {
      let pName_data = [];
      pName_data = this.pName_data.filter(
        function (e) {
          if (e.descript === select_laf) {
            return true;
          }
        }
      )

      let options = [];

      pName_data.forEach((e,i) => {
        let add_option = { value: e.code, text: e.code_name }
        options.push(add_option);
      });

      this.pnOptions = options;
      this.chargerData.pn_id = this.pnOptions[0].value;
    },
    changePTerminal(select_value) {
      if (select_value === "N") {
        this.chargerData.mid = "";
        this.chargerData.tem_id = "";
      } else if (select_value === "Y") {

      }
    },
    changeInstallDate(select_date) {
      let installDate = select_date.replaceAll("-", "").replaceAll(":", "").replaceAll(" ", "");
      this.chargerData.month = installDate;
      this.chargerData.year = installDate.subString(0,4);
    },
    searchReader(sid) {
      this.obj_reader.sid = this.chargerData.sid;
      this.$refs.modal_readerList.init();
      this.$bvModal.show("modal-reader-list");
    },
    selectReader(reader_id, mid, terminal_id, iccid) {
      this.chargerData.reader_id = reader_id;
      this.chargerData.mid = mid;
      this.chargerData.tem_id = terminal_id;
      this.select_iccid = iccid;
    },
    searchModel(maker) {
      this.obj_model.maker = this.chargerData.maker;
      this.$refs.modal_modelList.init();
      this.$bvModal.show("modal-model-list");
    },
    selectModel(model_id) {
      this.chargerData.model_id = model_id;
    },
    setData(select_rowData) {
      this.chargerData = select_rowData;

      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      if (this.obj.edit_type === "add" || this.obj.edit_type === "") {
        this.chargerData.sid = this.snameOptions[0].value;
        this.chargerData.type = this.typeOptions[0].value;
        this.chargerData.maker = this.makerOptions[0].value;
        this.chargerData.laf = this.lafOptions[0].value;
        this.chargerData.p_terminal = this.pTerminalOptions[0].value;
        this.chargerData.i_funnel = this.iFunnelOptions[0].value;
        this.chargerData.use_yn = this.useYnOptions[0].value;
        this.chargerData.pay = this.payOption[0].value;
        this.chargerData.c_capacity = this.capacityOptions[0].value;
        this.dual_yn = "N"
        this.chargerData.r_method = this.methodOptions[0].value;
        this.chargerData.as_id = this.asOptions[0].value;
        this.chargerData.sign = this.signOptions[0].value;
        this.chargerData.status = this.statusOptions[0].value;
        this.chargerData.setup = this.setupOptions[0].value;

        let temp_date = crt_date.substring(0,4) + "-" + crt_date.substring(4,6) + "-" + crt_date.substring(6,8);
        this.install_date = temp_date;
      } else {
        let date_str = this.chargerData.month.toString();
        let temp_date = date_str.substring(0,4) + "-" + date_str.substring(4,6) + "-" + date_str.substring(6,8);
        this.install_date = temp_date;
      }

      this.changeLaf(this.chargerData.laf);
      this.changeStation(this.chargerData.sid);
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
