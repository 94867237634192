<template>
  <b-modal
    id="modal-menu-add"
    title="메뉴 추가"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-tabs v-model="tabIndex" pills card>
          <b-tab title="상위메뉴" active @click="changeTab">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />법인명
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="menuData.company_id" :options="companyOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />권한 설정
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="menuData.authority" :options="authOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 경로
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.url"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 설명
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.descript"></b-form-input>
                </div>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="하위메뉴" @click="changeTab">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />법인명
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="menuData.company_id" :options="companyOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />상위 메뉴
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="menuData.r_menu_id" :options="rmenuOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />권한 설정
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="menuData.authority" :options="authOptions"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 경로
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.url"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />메뉴 설명
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="menuData.descript"></b-form-input>
                </div>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      company_data: Object,
      first_menu_data: Object,
      second_menu_data: Object,
    },
  },
  data() {
    return {
      tabIndex: 1,
      menuData: {
        company_id: "",
				name: "",
				url: "",
				descript: "",
				level: 1,
				order_no: 1,
				r_menu_id: "",
				authority: "S,A,U,C",
				reg_date: "",
				update_date: "",
				reg_id: "",
				use_yn: "Y",
				code: "",
      },
      companyOptions: [],
      rmenuOptions: [],
      authOptions: [
        { value: "S,A,U,C", text: "전체" },
        { value: "S", text: "최고 관리자" },
        { value: "S,A", text: "일반 관리자" },
        { value: "S,A,C", text: "CS 운영자" },
        { value: "S,A,U", text: "사용자" }
      ],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.admin_data = this.$store.getters.user;
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        var options = [];

        this.obj.first_menu_data.forEach((e,i) => {
          if (e.company_id === "EV") {
            var add_data = { text: e.name, value: e.code };
            options.push(add_data);
          }
        });

        this.companyOptions = this.obj.company_data;
        this.rmenuOptions = options;

        let crt_date = this.getToday();

        this.menuData.reg_id = this.admin_data.user_id;
        this.menuData.reg_date = crt_date.substring(0,4) + "-" + crt_date.substring(4,6) + "-" + crt_date.substring(6,8);
        this.menuData.update_date = crt_date.substring(0,4) + "-" + crt_date.substring(4,6) + "-" + crt_date.substring(6,8);
      });
    },
    async ok() {
      try {
        this.addMenu();

        window.alert("메뉴가 등록되었습니다.");

        var level_str = "";
        if (this.menuData.level == 1) {
          level_str = "상위메뉴";
        } else if (this.menuData.level == 2) {
          level_str = "하위메뉴";
        }

        var context = "메뉴ID : " + this.menuData.code + ", 메뉴 이름 : " + this.menuData.name + ", 메뉴경로 : " + this.menuData.url + " , 권한 : " + this.menuData.authority + " , 메뉴종류 : " + level_str;
        this.addAdminLog("메뉴 관리", "메뉴 등록", "메뉴 등록 팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-menu-add");
      }  catch (error) {
        window.alert("메뉴 등록에 실패했습니다.");
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.menuData = select_rowData;
    },
    async addMenu() {
      let crt_date = this.getToday();
      var menu_code = "";
      var r_menu_code = "";
      var company_id = "";
      if (this.menuData.company_id == "all" || this.menuData.company_id == "EV") {
        company_id = "EV";
      } else {
        company_id = this.menuData.company_id;
      };
      var menu_no = 0;
      var menu_url = "/" + this.menuData.r_menu_code + "/" + this.menuData.url;

      // 메뉴코드 생성
      if (this.tabIndex == 1) {			// 상위 메뉴
        r_menu_code = "";
        menu_no = this.rmenuOptions.length + 1;
        var max_regNo = '00' + String(menu_no);
        var mod_regNo = max_regNo.slice(-2);

        if (company_id == "EV") {
          menu_code = "MN" + mod_regNo;
        } else {
          menu_code = company_id + "_MN" + mod_regNo;
        };
      } else if (this.tabIndex == 2) {		// 하위 메뉴
        r_menu_code = this.menuData.r_menu_id;

        var second_menu = [];

        this.obj.second_menu_data.forEach((e,i) => {
          if (e.company_id === company_id && e.r_menu_id === r_menu_code) {
            second_menu.push(e);
          }
        });

        menu_no = second_menu.length + 1;
        
        var max_regNo = '00' + String(menu_no);
        var mod_regNo = max_regNo.slice(-2);

        if (company_id == "EV") {
          menu_code = r_menu_code + mod_regNo;
        } else {
          menu_code = company_id + "_" + r_menu_code + mod_regNo;
        };
      };

      try {
        const { data } = await this.$axios.post(
          "/api/addMenu", {
            company_id: this.menuData.company_id,
            name: this.menuData.name,
            url: menu_url,
            descript: this.menuData.descript,
            level: 1,
            order_no: 1,
            r_menu_id: this.menuData.r_menu_id,
            authority: this.menuData.authority,
            reg_date: crt_date,
            update_date: crt_date,
            reg_id: this.admin_data.user_id,
            use_yn: "Y",
            code: menu_code,
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("OCPP 충전기 정보 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
