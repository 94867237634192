import Vue from "vue";
import Vuex from "vuex";
// 샌드버드 오류창 숨기려고 주석처리
import sb from "@/plugins/sendbird";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userType: null,
    token: null,
    user: null,
    userData: null,
    sbUser: null,
    loaded: false,
  },
  mutations: {
    set(s, [key, value]) {
      s[key] = value;
    },
  },
  getters: {
    token(s) {
      return s.token;
    },
    user(s) {
      return s.user;
    },
    userData(s) {
      return s.userData;
    },
    sbUser(s) {
      return s.sbUser;
    },
    loaded(s) {
      return s.loaded;
    },
  },
  actions: {
    async getMe({ commit, getters }, p) {
      const token =
        p.token ||
        localStorage.getItem("duruscoev__token") ||
        sessionStorage.getItem("duruscoev__token");

      if (p.autoLogin) {
        localStorage.setItem("duruscoev__token", token);
        axios.defaults.headers.common["Authorization"] = token;
      } else {
        sessionStorage.setItem("duruscoev__token", token);
        axios.defaults.headers.common["Authorization"] = token;
      }

      try {
        const { data } = await axios.get("/users/me");
        const user = data.data;

        commit("set", ["user", user]);
        commit("set", ["loaded", true]);

        sb.connect(getters.user.serviceId, (sbUser, err) => {
          commit("set", ["sbUser", sbUser]);
          // console.log(sbUser);
        });

        return user;
      } catch (error) {}

      return;
    },

    setMe({ commit, getters, user }, p){
      try {
        commit("set", ["user", user]);
        commit("set", ["loaded", true]);

        sb.connect(getters.user.serviceId, (sbUser, err) => {
          commit("set", ["sbUser", sbUser]);
          // console.log(sbUser);
        });

        return user;
      } catch (error) {}

      return;
    },

    async setUserData({ commit, getters }, p){
      try {
        commit("set", ["user", p.userData]);
        commit("set", ["userData", p.userData]);
        commit("set", ["loaded", true]);

        return p.userData;
      } catch (error) {}

      return;
    }
  },
  modules: {},
});
