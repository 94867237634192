<template>
  <div class="d-flex flex-column align-items-center justify-content-between" style="border-style: ridge; border-radius: 10px; box-shadow: 4px 4px 10px rgba(87, 70, 156, 0.3);">
    <div style="background-color: #3279d3; color: #fff; font-weight: bold; border-radius: 10px; height: 50px; display: flex; align-items: center; justify-content: center; width: -webkit-fill-available;">
      <span>{{ obj.title }}</span>
    </div>
    <div>
      <span style="flex: 1; font-weight: bold; font-size: 20px; margin-right: 10px;">{{ obj.main_str }}</span>
      <span style="flex: 1;">{{ obj.sub_str }}</span>
    </div>
    <div>
      <span style="flex: 1;">1주전 : {{ obj.first_line }}</span>
    </div>
    <div>
      <span style="flex: 1;">2주전 : {{ obj.second_line }}</span>
    </div>
    <div>
      <span style="flex: 1;" :style="{'color': obj.third_type}">전주 대비 {{ obj.third_line }}</span>
    </div>
    <b-button class="btn_def" style="background: #3279d3; color: #fff; margin-bottom: 10px; height: 50px;" @click="detailCall">상세보기</b-button>

    <!-- modals -->
    <modal-dashboard-detail ref="modal_dashboardDtl" :obj="obj.data"/>
  </div>
</template>

<script>
import Modals from "@/components/Modals/index";

export default {
  components: {
    // Modals
    ModalDashboardDetail: Modals.DashboardDetail,
  },
  mounted() {
    this.init();
  },
  props: {
    obj: {
      title: String,
      main_str: String,
      sub_str: String,
      first_line: String,
      second_line: String,
      third_line: String,
      third_type: String,
      data: Object,
    },
  },
  data() {
    return {
      third_textColor: "red",
    }
  },
  methods: {
    init() {
      /*
      if (this.obj.third_type === "up") {
        this.third_textColor = "red";
      } else if (this.obj.third_type === "down") {
        this.third_textColor == "blue";
      }
        */
    },
    detailCall() {
      this.$bvModal.show("modal-dashboard-detail");
    }
  }
};
</script>

<style lang="scss" scoped></style>
