<template>
  <footer>
    <article class="border-top">
      <div style="width: 105vw; height: 50px; background-color: #3279d3;"></div>
    </article>
  </footer>
</template>

<script>
export default {
  data() {
    return {
    }
  },
};
</script>

<style lang="scss" scoped></style>
