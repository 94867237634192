<template>
  <b-modal
    id="modal-show-map"
    title="충전소 위치"
    ok-title="닫기"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    @shown="initMap"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxHeight: '800px' }"
    >
      <div id="map-container">
        <div>
          <div
            ref="map"
            :style="{
              width: '100%',
              height: '360px',
              maxWidth: '90vw',
              maxHeight: '50vh',
            }"
          ></div>
          <div>
            {{obj.address}}
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  components: {
    
  },
  props: {
    obj: {
      address: String,
      addressDetail: String,
    },
    // 지도 센터
    center: {
      lng: 127.04454,
      lat: 37.55101,
    },
  },
  data() {
    return {
      lat: null,
      lng: null,
    };
  },
  watch: {
    obj(n) {
      this.initMap(n);
    },
  },
  mounted() {
    if (this.obj.address) {
      this.initMap();
    }
  },
  methods: {
    initMap() {
      // 맵 표시할 곳
      var container = this.$refs.map;
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(33.450701, 126.570667), //지도의 중심좌표.
        level: 2, //지도의 레벨(확대, 축소 정도)
      };
      var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴

      // 주소-좌표 변환 객체를 생성합니다
      var geocoder = new kakao.maps.services.Geocoder();

      // 주소로 좌표를 검색합니다
      geocoder.addressSearch(this.obj.address, function(result, status) {
        // 정상적으로 검색이 완료됐으면
        if (status === kakao.maps.services.Status.OK) {
          var coords = new kakao.maps.LatLng(result[0].y, result[0].x);

          // 네이버 매물검색을 위해 주소로 X,Y좌표 변환
          // naver.maps.Service.geocode({ query : result[0].address.address_name }, function(status, response) {
          //   if (status === naver.maps.Service.Status.ERROR) {
          //     return alert('Something wrong!');
          //   }
          //   console.log(response)
          // });

          var marker = new kakao.maps.Marker({
            map: map,
            position: coords,
          });

          // 인포윈도우로 장소에 대한 설명을 표시합니다
          // var infowindow = new kakao.maps.InfoWindow({
          //   content: `<div style="width:150px;text-align:center;padding:6px 0;">${this.address}</div>`,
          // });
          // infowindow.open(map, marker);

          // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
          map.setCenter(coords);
        }
      });
    },
    ok() {
      this.$bvModal.hide("modal-show-map");
      //this.$root.$emit("hb::pdf::hidden", true);
    },
  },
};
</script>

<style lang="scss" scoped></style>
