<template>
  <b-modal
    id="modal-card-status-change"
    :title="obj.title"
    :ok-title="obj.ok_title"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div v-if="obj.edit_type === 'receive'">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.user_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />카드번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="cardData.card_no"></b-form-input>
          </div>
        </b-row>
      </div>
      <div v-else-if="obj.edit_type === 'status'">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.user_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />카드번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="cardData.card_no"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />현재 상태
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="obj.card_data.status"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />변경 상태
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="cardData.status" :options="statusOptions"></b-form-select>
          </div>
        </b-row>
      </div>
      <div v-else-if="obj.edit_type === 'send'">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.user_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />카드번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="cardData.card_no"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />주소
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.full_addr"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />연락처
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="cardData.phone"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />발송 일자
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type="date" v-model="cardData.send_date"></b-form-input>
          </div>
        </b-row>
      </div>
      <div v-else-if="obj.edit_type === 'reissuance'">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.user_id"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />회원 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />이전 카드 번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="obj.card_data.card_no"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />신규 카드 번호
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type=number v-model="cardData.card_no"></b-form-input>
            <b-button class="btn_def" @click="findCard">검색</b-button>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />기본 주소
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.full_addr"></b-form-input>
            <div class="d-flex align-items-center">
              <b-form-group class="ml-auto text-gray">
                <b-form-checkbox v-model="addr_copy" @change="changeAddrCopy">
                  배송지 동일
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />배송지 주소
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.addr"></b-form-input>
            <b-button class="btn_def" @click="getPostCode">검색</b-button>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />배송지 상세주소
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" v-model="cardData.addr_dtl"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />연락처
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input readonly style="height: 50px; border-radius: 10px;" type=number v-model="cardData.phone"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />발송 일자
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" type="date" v-model="cardData.send_date"></b-form-input>
          </div>
        </b-row>
      </div>
    </div>
    <div class="pt-3">
      <div ref="addr" />
    </div>

    <!-- modals -->
    <modal-nomatch-card @input="input" />
  </b-modal>
</template>

<script>
import KakaoMap from "@/components/KakaoMap.vue";

import ModalNomatchCard from "./NomatchCard.vue";

export default {
  components: {
    KakaoMap,
    ModalNomatchCard,
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      edit_type: String,
      title: String,
      ok_title: String,
      card_data: Object,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      cardData: [],
      crt_status: "",
      crt_address: "",
      addr_copy: false,
      select_cardData: {},
      statusOptions: [
        { value: "lost", text: "분실" },
        { value: "stop", text: "정지" }
      ],
      addressObj: {},
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.cardData = this.obj.card_data;
        this.cardData.full_addr = this.obj.card_data.addr + " " + this.obj.card_data.addr_dtl;
      });
    },
    async ok() {
      try {
        if (this.obj.edit_type === "send") {
          this.updateCardSend();
        } else if (this.obj.edit_type === "status") {
          this.updateCardLost();
        } else if (this.obj.edit_type === "receive") {
          this.updateCardReceive();
        } else if (this.obj.edit_type === "reissuance") {
          this.updateCardRematchStop();
        }

        if (this.obj.edit_type === "send") {
          window.alert("회원카드 발급이 완료되었습니다.");
          var context = "회원ID : " + this.cardData.user_id + ", 카드No : " + this.cardData.card_no + " , 발송일자 : " + this.cardData.send_date;
          this.addAdminLog("회원카드 발급관리", "카드발송 요청", "카드발송 요청팝업", context, this.admin_data.user_id);
        } else if (this.obj.edit_type === "status") {
          window.alert("해당 카드가 분실처리되었습니다.");
          var context = "회원ID : " + this.cardData.user_id + ", 카드No : " + this.cardData.card_no;
          this.addAdminLog("회원카드 발급관리", "회원 카드 분실", "회원카드 변경 팝업", context, this.admin_data.user_id);
        } else if (this.obj.edit_type === "receive") {
          window.alert("회원카드 수신이 확인되었습니다.");
          var context = "회원ID : " + this.cardData.user_id + ", 카드No : " + this.cardData.card_no;
          this.addAdminLog("회원카드 발급관리", "회원 수신", "회원카드 수신확인 팝업", context, this.admin_data.user_id);
        } else if (this.obj.edit_type === "reissuance") {
          window.alert("회원카드 재발급이 완료되었습니다.");
          var context = "회원ID : " + this.cardData.user_id + ", 이전 카드No : " + obj.card_data.card_no + ", 재발급 카드No : " + this.cardData.card_no + " , 발송일자 : " + this.cardData.send_date;
          this.addAdminLog("회원카드 발급관리", "카드 재발급 요청", "카드 재발급 요청팝업", context, this.admin_data.user_id);
        }

        this.$emit("reflash");
        this.$bvModal.hide("modal-card-status-change");
        
        //this.$root.$emit("hb::pdf::hidden", true);
      }  catch (error) {
        if (this.obj.edit_type === "send") {
          window.alert("회원카드 발급에 실패했습니다.");
        } else if (this.obj.edit_type === "status") {
          window.alert("해당 카드의 분실처리가 실패했습니다.");
        } else if (this.obj.edit_type === "receive") {
          window.alert("회원카드 수신 확인에 실패했습니다.");
        } else if (this.obj.edit_type === "reissuance") {
          window.alert("회원카드 재발급에 실패했습니다.");
        }

        console.log(error);
      }
    },
    async updateCardSend() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateCardSend", {
            card_no : this.cardData.card_no,
            send_date : crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async updateCardLost() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateCardLost", {
            card_no : this.cardData.card_no,
            lost_date : crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async updateCardReceive() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateCardReceive", {
            card_no : this.cardData.card_no,
            receive_date : crt_date
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async updateCardRematchStop() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateCardRematchStop", {
            card_no : this.cardData.card_no,
            stop_date : crt_date
          }
        );

        this.addUserCardReMatch();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addUserCardReMatch() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addUserCardReMatch", {
            card_no: this.cardData.card_no,
						user_id: this.cardData.user_id,
						status: "send",
						type: this.cardData.card_type,
						card_stop_yn: "N",
						send_date: this.cardData.send_date,
						reg_date: crt_date,
						reg_user: this.admin_data.user_id
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.cardData = select_rowData;
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("법인 정보 변경 로그 등록을 실패했습니다.");
      }
    },
    foldDaumzonecode() {
      // iframe을 넣은 element를 안보이게 한다.
      this.wrap.style.display = "none";
    },
    getPostCode() {
      // 현재 scroll 위치를 저장해놓는다.
      var currentScroll = Math.max(
        document.body.scrollTop,
        document.documentElement.scrollTop
      );
      new daum.Postcode({
        //autoMappingJibun: false, //연관주소가 지번일 때 지번 주소 자동매핑을 막음
       // autoMappingRoad: false, //연관주소가 도로명일 때  주소 자동매핑을 막음

        oncomplete: (data) => {
          console.log(data);
          // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

          // 각 주소의 노출 규칙에 따라 주소를 조합한다.
          // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
          var addr = ""; // 주소 변수
          var extraAddr = ""; // 참고항목 변수

          //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
          if (data.userSelectedType === "R") {
            // 사용자가 도로명 주소를 선택했을 경우
            addr = data.roadAddress;
          } else {
            // 사용자가 지번 주소를 선택했을 경우(J)
            addr = data.jibunAddress;
          }

          // 사용자가 선택한 주소가 도로명 타입일때 참고항목을 조합한다.
          if (data.userSelectedType === "R") {
            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            // 조합된 참고항목을 해당 필드에 넣는다.
          }
          // 우편번호와 주소 정보를 해당 필드에 넣는다.
          //===================================================================
          //jibunAddressEnglish 또는 autoJibunAddressEnglish에 랜덤으로 값이 담겨 필터링 추가함
          let jibun = "";

          if (data.autoJibunAddressEnglish === "") {
            jibun = data.jibunAddressEnglish.split(",")[0];
          } else {
            jibun = data.autoJibunAddressEnglish.split(",")[0];
          }

          //====================================================================
          this.add = jibun;
          this.addressObj = {
            gps_x: "",
            gps_y: "",
            zipNo: data.zonecode,
            address: `${addr} ${extraAddr}`,
            sido: data.sido,
            jibunAddress:
              data.jibunAddress !== ""
                ? data.jibunAddress
                : data.autoJibunAddress,
            roadAddress:
              data.roadAddress != "" ? data.roadAddress : data.autoRoadAddress,
            addressDetailMeta: {
              bcode: data.bcode,
              bname: data.bname,
              bname1: data.bname1,
              buildingCode: data.buildingCode,
              buildingName: data.buildingName,
              bun: jibun.split("-")[0] || "",
              ji: jibun.split("-")[1] || "",
              sido: data.sido,
              sigungu: data.sigungu,
              sigunguCode: data.sigunguCode,
            },
          };

          this.companyData.addr = this.addressObj.address;
          
          const geocoder = new kakao.maps.services.Geocoder();

          let gps_x = "";
          let gps_y = "";

          // 주소로 좌표를 검색합니다
          geocoder.addressSearch(`${addr} ${extraAddr}`, function(result, status) {
            // 정상적으로 검색이 완료됐으면
            if (status === kakao.maps.services.Status.OK) {
              console.log("OK",result)
              const coords = new kakao.maps.LatLng(result[0].y, result[0].x);
              //const message = 'latlng: new kakao.maps.LatLng(' + result[0].y + ', ';
              //message += result[0].x + ')';

              gps_x = result[0].x;
              gps_y = result[0].y;
            }
          });

          this.companyData.gps = gps_x + "," + gps_y;
          this.addressObj.gps_x = gps_x;
          this.addressObj.gps_y = gps_y;
          // 커서를 상세주소 필드로 이동한다.
          // this.$refs.addrDetail.$el.focus();

          // iframe을 넣은 element를 안보이게 한다.
          // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
          this.wrap.style.display = "none";

          // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
          document.body.scrollTop = currentScroll;
        },

        // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
        // onresize: (size) => {
        //   this.wrap.style.height = size.height + "px";
        // },
        // width: "100%",
        // height: "100%",
      }).open({ autoClose: true }); //주소 선택시 팝업창 닫기
      // iframe을 넣은 element를 보이게 한다.
      // this.wrap.style.display = "block";
      // test(address);
    },
    findCard() {
      this.$bvModal.show("modal-nomatch-card");
    },
    input(select_card_data) {
      this.select_cardData = select_card_data;
    }
  },
};
</script>

<style lang="scss" scoped></style>
