<template>
  <b-modal
    id="modal-dashboard-detail"
    :title="obj.title"
    ok-title="닫기"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="false"
    size="lg"
    ref="element"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '1200px', maxHeight: '1000px' }"
    >
      <div>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
            <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
              <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="changeSearchKey" :options="searchKeyOption"></b-form-select>
              <b-form-input 
                size="sm"
                v-model="filterData.searchKey"
                style="flex: 3; height: 50px; border-radius: 10px;"
                @change="changeSearchKey"
              ></b-form-input>
            </div>
          </b-col>
          <b-col lg=6>
            
          </b-col>
        </b-row>
        <b-row style="margin: 0px 0px 10px 0px;">
          <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
            <span>전체 {{ gridFilterData.length }} 건</span>
          </b-col>
          <b-col lg=6 class="d-flex justify-content-end">
            <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
          </b-col>
        </b-row>
        <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
          <div class="table-wrapper2" style="width: -webkit-fill-available;" >
            <b-table 
              id = "dashboardDetail_grid"
              striped hover 
              :items="gData"
              :fields="gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="">
                  {{ row.index + 1 + ((currentPage - 1) * perPage) }}
                </div>
              </template>
              <template #cell(sname)="row">
                <div style="">
                  {{ gData[row.index].sname }}
                </div>
              </template>
              <template #cell(sid)="row">
                <div style="">
                  {{ gData[row.index].sid }}
                </div>
              </template>
              <template #cell(cid)="row">
                <div style="">
                  {{ gData[row.index].cid }}
                </div>
              </template>
              <template #cell(tel_num)="row">
                <div style="">
                  {{ gData[row.index].tel_num }}
                </div>
              </template>
              <template #cell(tran_id)="row">
                <div style="">
                  {{ gData[row.index].tran_id }}
                </div>
              </template>
              <template #cell(user_type)="row">
                <div style="">
                  {{ gData[row.index].user_type }}
                </div>
              </template>
              <template #cell(user_card)="row">
                <div style="">
                  {{ gData[row.index].user_card }}
                </div>
              </template>
              <template #cell(laf)="row">
                <div style="">
                  {{ gData[row.index].laf }}
                </div>
              </template>
              <template #cell(code_name)="row">
                <div style="">
                  {{ gData[row.index].code_name }}
                </div>
              </template>
              <template #cell(u_electricity)="row">
                <div style="">
                  {{ gData[row.index].u_electricity }}
                </div>
              </template>
              <template #cell(charge)="row">
                <div style="">
                  {{ gData[row.index].charge }}
                </div>
              </template>
              <template #cell(s_date)="row">
                <div style="">
                  {{ gData[row.index].s_date }}
                </div>
              </template>
              <template #cell(e_date)="row">
                <div style="">
                  {{ gData[row.index].e_date }}
                </div>
              </template>
            </b-table>
          </div>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="gridFilterData.length"
            :per-page="perPage"
            first-text="⏮"
            prev-text="⏪"
            next-text="⏩"
            last-text="⏭"
            class="mt-4"
            size="sm"
          ></b-pagination>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    
  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      sname: String,
      sid: String,
      cid: String,
      tel_num: String,
      tran_id: String,
      user_type: String,
      user_card: String,
      laf: String,
      code_name: String,
      u_electricity: String,
      charge: String,
      s_date: String,
      e_date: String,
    },
  },
  data() {
    return {
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [],
      gridFilterData: [],
      gData: [],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "sname",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소ID"
        },
        {
          key: "cid",
          label: "충전기ID"
        },
        {
          key: "tel_num",
          label: "단말기번호"
        },
        {
          key: "tran_id",
          label: "충전ID"
        },
        {
          key: "user_type",
          label: "회원종류"
        },
        {
          key: "user_card",
          label: "회원 카드번호"
        },
        {
          key: "laf",
          label: "충전타입"
        },
        {
          key: "code_name",
          label: "충전속도"
        },
        {
          key: "u_electricity",
          label: "충전량"
        },
        {
          key: "charge",
          label: "충전금액"
        },
        {
          key: "s_date",
          label: "충전시작시간"
        },
        {
          key: "e_date",
          label: "충전종료시간"
        }
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: ""
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "충전소 ID", value: "sid" },
        { text: "충전기 이름", value: "sname" },
        { text: "단말기 번호", value: "tel_num" },
      ],
      select_index: -1,
      select_rowData: {},
      wrap: null,
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.crt_width = 1100;
    this.crt_height = 600;

    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.gridDefaultData = this.obj;
      });
    },
    changeSearchKey() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchKeyType;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.tel_num.indexOf(search_key) != -1 || e.sid.indexOf(search_key) != -1 || e.sname.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tel_num") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.tel_num.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sid") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sid.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "sname") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.sname.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      this.pageSet();
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 60;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 40;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "dashboard_detail_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'dashboard_detail_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    async ok() {
      this.$bvModal.hide("modal-dashboard-detail");
    },
  },
};
</script>

<style lang="scss" scoped></style>
