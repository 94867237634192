<template>
  <div class="" style="height: inherit;" ref="element">
    <b-container>
      <b-row style="margin: 20px 0px; height: 50px;">
        <b-col lg=6 class="d-flex flex-row align-items-center">
          <span style="margin-right: 20px;">충전기 종류</span>
          <div>
            <!-- 라디오 버튼 -->
            <b-form-group class="radio-group-sqaure" style="margin: 0px;">
              <b-form-radio-group
                :options="typeOption"
                v-model="filterData.type"
                buttons
                button-variant="light-gray rounded-1"
                @change="setData"
              />
            </b-form-group>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center align-items-center" style="gap: 20px;">
          <span style="">기간 조회</span>
          <div class="d-flex" style="flex: 1; gap: 10px;">
            <b-form-select size="sm" v-model="filterData.year" :options="searchYearOptions" @change="changeDate"></b-form-select>
            <b-form-select size="sm" v-model="filterData.month" :options="searchMonthOptions" @change="changeDate"></b-form-select>
            <b-form-select size="sm" v-model="filterData.week" :options="searchWeekOptions" @change="changeWeek"></b-form-select>
          </div>
          <b-button class="btn_def" style="height: 50px;" @click="DownloadExcel">엑셀다운</b-button>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 20px 0px;">
        <span>{{ filterData.year }}년 {{ filterData.month }}월 {{ filterData.week }}주 {{ filterData.type_str }}</span>
      </b-row>
      <b-row class="d-flex flex-column" style="width: inherit; margin: 0px;">
        <div class="d-flex flex-column">
          <div style="margin-bottom: 20px;">
            <div style="margin: 0px 0px 10px 0px;">
              <b-img></b-img>
              <span style="flex: 1;">충전기 주간 총 합계</span>
            </div>
            <div class="d-flex" style="gap: 10px;">
              <per style="flex: 1;" ref="modal_stationDtl" :obj="obj_per" />
              <count style="flex: 1;" ref="modal_stationDtl" :obj="obj_count" />
              <value style="flex: 1;" ref="modal_stationDtl" :obj="obj_value" />
              <amount style="flex: 1;" ref="modal_stationDtl" :obj="obj_amount" />
              <user style="flex: 1;" ref="modal_stationDtl" :obj="obj_user" />
            </div>
          </div>
          <div style="margin-bottom: 20px;">
            <div style="margin: 0px 0px 10px 0px;">
              <b-img></b-img>
              <span style="flex: 1;">충전기 용량별 사용 통계</span>
            </div>
            <div class="d-flex" style="gap: 10px;">
              <t25 style="flex: 1;" ref="modal_stationDtl" :obj="obj_25" />
              <t50 style="flex: 1;" ref="modal_stationDtl" :obj="obj_50" />
              <t75 style="flex: 1;" ref="modal_stationDtl" :obj="obj_75" />
              <t150 style="flex: 1;" ref="modal_stationDtl" :obj="obj_150" />
              <tmax style="flex: 1;" ref="modal_stationDtl" :obj="obj_max" />
            </div>
          </div>
        </div>
        <div>
          <div style="margin: 0px 0px 10px 0px;">
            <b-img></b-img>
            <span style="flex: 1;">충전기 TOP 50 랭킹(충전량)</span>
          </div>
          <div class="table-wrapper2" style="width: inherit;">
            <b-table 
              id = "grid"
              bordered hover noCollapse 
              :items="gData"
              :fields="gridFields"
              small
            >
              <template #cell(No)="row">
                <div style="">
                  {{ row.index + 1 }}
                </div>
              </template>
              <template #cell(s_name)="row">
                <div style="">
                  {{ gData[row.index].sname }}
                </div>
              </template>
              <template #cell(sid)="row">
                <div style="">
                  {{ gData[row.index].sid }}
                </div>
              </template>
              <template #cell(cid)="row">
                <div style="">
                  {{ gData[row.index].cid }}
                </div>
              </template>
              <template #cell(laf_type)="row">
                <div style="">
                  {{ gData[row.index].laf }}
                </div>
              </template>
              <template #cell(count)="row">
                <div style="">
                  {{ gData[row.index].charging_count }}
                </div>
              </template>
              <template #cell(value)="row">
                <div style="">
                  {{ gData[row.index].charging_value }}
                </div>
              </template>
              <template #cell(amount)="row">
                <div style="">
                  {{ gData[row.index].charging_amount }}
                </div>
              </template>
              <template #cell(user_count)="row">
                <div style="">
                  {{ gData[row.index].charging_user }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </b-row>
      <!--
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>
      -->
      <!-- modals -->
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Component from "@/components/Form/index";

import Modals from "@/components/Modals/index";

import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    Per: Component.DashboardCard,
    Count: Component.DashboardCard,
    Value: Component.DashboardCard,
    Amount: Component.DashboardCard,
    User: Component.DashboardCard,

    T25: Component.DashboardCard,
    T50: Component.DashboardCard,
    T75: Component.DashboardCard,
    T150: Component.DashboardCard,
    Tmax: Component.DashboardCard,
    // Modals
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      admin_data: {},
      crt_date: null,
      charge_count_data: 0,
      charging_info: [],
      charging_list_temp: [],
      cid_charging_data: [],
      week_list: [],
      dashboard_data: [],
      cp_top50_data: [],
      cp_capacity_data: [],
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        sid : "",
        name : "",
        addr : "",
        addr_dtl : "",
        kind : "",
        kind_dtl : "",
        use_time : "24",
        bname : "",
        bid : "",
        bis_id : "",
        reserv_yn : "Y",
        limit_yn : "Y",
        free_yn : "Y",
        fee_id : "",
        note : "",
      },
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "s_name",
          label: "충전소명"
        },
        {
          key: "sid",
          label: "충전소 ID"
        },
        {
          key: "cid",
          label: "충전기 ID"
        },
        {
          key: "laf_type",
          label: "완속/급속"
        },
        {
          key: "count",
          label: "충전횟수(회)"
        },
        {
          key: "value",
          label: "충전량(kWh)"
        },
        {
          key: "amount",
          label: "금액(원)"
        },
        {
          key: "user_count",
          label: "이용자 수(명)"
        },
      ],
      filterData: {
        type: "all",
        type_str: "전체",
        year: 0,
        month: 0,
        week: 0,
      },
      typeOption: [
        { text: "전체", value: "all" },
        { text: "완속", value: "L" },
        { text: "급속", value: "F" },
      ],
      searchYearOptions:[
        { text: "2024년", value: 1 },
      ],
      searchMonthOptions: [
        { text: "1월", value: 1 },
        { text: "2월", value: 2 },
        { text: "3월", value: 3 },
        { text: "4월", value: 4 },
        { text: "5월", value: 5 },
        { text: "6월", value: 6 },
        { text: "7월", value: 7 },
        { text: "8월", value: 8 },
        { text: "9월", value: 9 },
        { text: "10월", value: 10 },
        { text: "11월", value: 11 },
        { text: "12월", value: 12 },
      ],
      searchWeekOptions: [
        { text: "1주", value: 1 },
      ],
      file_type: "station_list",
      rules: {
        email: [
          (v) =>
            !!v || { title: "이메일 입력", message: "이메일을 입력하세요." },
          (v) =>
            regex.serviceId.test(v) || {
              title: "이메일 형식",
              message: "이메일 형식이 올바르지 않습니다.",
            },
        ],
        mobile: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호를 입력하세요.",
            },
          (v) =>
            v.length >= 10 || {
              title: "핸드폰 번호 입력",
              message: "휴대폰 번호 형식이 올바르지 않습니다.",
            },
        ],
        code: [
          (v) =>
            !!v || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            v.length === 4 || {
              title: "핸드폰 번호 인증",
              message: "인증번호를 입력하세요.",
            },
          (v) =>
            (v.length === 4 && this.serverValid.code) || {
              title: "핸드폰 번호 인증",
              message: "핸드폰 번호를 인증해주세요.",
            },
        ],
        password: [
          (v) =>
            !!v || {
              title: "비밀번호 입력",
              message: "비밀번호를 입력하세요.",
            },
          (v) =>
            regex.password.test(v) || {
              title: "비밀번호 형식",
              message: "8-16자 영문 대/소문자, 숫자, 특수문자를 사용하세요.",
            },
        ],
        passwordConfirm: [
          (v) =>
            !!v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인을 입력하세요.",
            },
          (v) =>
            this.resetPassword.newPassword === v || {
              title: "비밀번호 확인",
              message: "비밀번호 확인이 일치하지 않습니다",
            },
        ],
      },
      obj_per: {
        title: "충전기 이용률",
        main_str: "0.0%",
        sub_str: "(0/0)",
        first_line: "0.0% (0/0)",
        second_line: "0.0% (0/0)",
        third_line: "0.0% 증가",
        third_type: "red",
        data: [],
      },
      obj_count: {
        title: "충전횟수",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
      obj_value: {
        title: "충전량",
        main_str: "0.0",
        sub_str: "kWh",
        first_line: "0.0kWh",
        second_line: "0.0kWh",
        third_line: "0.0kWh 증가",
        third_type: "red",
        data: [],
      },
      obj_amount: {
        title: "충전요금",
        main_str: "0",
        sub_str: "원",
        first_line: "0원",
        second_line: "0원",
        third_line: "0원 증가",
        third_type: "red",
        data: [],
      },
      obj_user: {
        title: "이용자 수",
        main_str: "0",
        sub_str: "명",
        first_line: "0명",
        second_line: "0명",
        third_line: "0명 증가",
        third_type: "red",
        data: [],
      },
      obj_25: {
        title: "25kWh 이하(0.0%)",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
      obj_50: {
        title: "25kWh ~ 50kWh(0.0%)",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
      obj_75: {
        title: "50kWh ~ 75kWh(0.0%)",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
      obj_150: {
        title: "75kWh ~ 150kWh(0.0%)",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
      obj_max: {
        title: "150kWh 이상(0.0%)",
        main_str: "0",
        sub_str: "회",
        first_line: "0회",
        second_line: "0회",
        third_line: "0회 증가",
        third_type: "red",
        data: [],
      },
    };
  },
  computed: {
    
  },
  watch: {
    
  },
  async mounted() {
    $("#gnb").show();
    $("#footer").show();
    
    this.admin_data = this.$store.getters.user;
    var crt_date = new Date();									// 현재일시
    this.crtDate = crt_date;
    var crt_year = parseInt(crt_date.getFullYear());			// 현재년도
    var start_year = 2024;
    var year_count = crt_year - start_year + 1;
    var year_data = [];
    var a = 0;
    for (a=0; a < year_count; a++) {
      var year_value = start_year + a;
      var year_arr = {text: year_value.toString() + "년", value: year_value};
      year_data.push(year_arr);
    }

    this.searchYearOptions = year_data;
    this.filterData.year = this.searchYearOptions[year_count - 1].value;

    this.selectChargerCount(crt_date);
  },
  methods: {
    async selectChargerCount(date) {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerCount", {
//          "/selectChargerCount", {
            
          }
        );
        if (data > 0) {
          this.charge_count_data = data;
          
          this.weekNumOfMonth(date);
        } else if (data.length == 0) {
          window.alert("데이터가 없습니다.");
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    changeDate() {debugger;
      var select_date = new Date(this.filterData.year, this.filterData.month - 1, 1);
      this.weekNumOfMonth(select_date);
    },
    weekNumOfMonth(date) {
      // 월요일을 중심으로한 주차 구하기( JS기준 : 일요일 0 월요일 1 ~ 토요일 6 )
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      var month = date.getMonth() + 1;
      var date_str = date.getFullYear().toString() + month.toString().padStart(2, "0") + date.getDate().toString().padStart(2, "0");

      const diffDate = lastDay.getTime() - firstDay.getTime();
      var diff_date = Math.abs(diffDate / (1000 * 60 * 60 * 24));

      var weekObj = null;
      var weekObjArray = new Array();
      var weekStand = 1;  // 월요일 고정
      var weekNum = 1;
      var thisMonthFirstWeek = firstDay.getDay();

      for(var num = 0; num < diff_date + 1; num++) {
          // 마지막월과 첫번째월이 다른경우 빠져나온다.
          //if(lastDay.getMonth() != firstDay.getMonth()) {
          //    break;
          //}
          weekObj = new Object();

          if(thisMonthFirstWeek == weekStand) {
              if(firstDay.getDay() == weekStand) {
                  if(weekObjArray.length > 0) {
                    weekNum ++;
                  }
              }

              weekObj.date = firstDay.getFullYear().toString() + (firstDay.getMonth() + 1).toString().padStart(2, "0") + firstDay.getDate().toString().padStart(2, "0");
              weekObj.weekNum = weekNum;
              weekObjArray.push(weekObj);
          } else {
            var for_count = 0;
            if (thisMonthFirstWeek > weekStand) {
              for_count = thisMonthFirstWeek - weekStand + 1;
            } else {
              for_count = 8 - (weekStand - thisMonthFirstWeek);
            }

            for (var i=0; i < for_count; i++) {
              var insert_date = new Date(date.getFullYear(), date.getMonth(), 1);
              insert_date.setDate(firstDay.getDate() - i);
              var weekObj2 = {};
              weekObj2.date = insert_date.getFullYear().toString() + (insert_date.getMonth() + 1).toString().padStart(2, "0") + insert_date.getDate().toString().padStart(2, "0");
              weekObj2.weekNum = weekNum;
              weekObjArray.push(weekObj2);
            }

            for (var a=0; a < 14; a++) {
              var insert_date2 = new Date(date.getFullYear(), date.getMonth(), 1);
              insert_date2.setDate(firstDay.getDate() - for_count - a); 

              if ( a < 7 ) {
                var temp_arr = {date: insert_date2.getFullYear().toString() + (insert_date2.getMonth() + 1).toString().padStart(2, "0") + insert_date2.getDate().toString().padStart(2, "0"), weekNum: 0};
              } else {
                var temp_arr = {date: insert_date2.getFullYear().toString() + (insert_date2.getMonth() + 1).toString().padStart(2, "0") + insert_date2.getDate().toString().padStart(2, "0"), weekNum: -1};
              };
              weekObjArray.push(temp_arr);
            }

            thisMonthFirstWeek = weekStand;
          }
          firstDay.setDate(firstDay.getDate() + 1);
      }

      if (lastDay.getDay() >= weekStand) {
        var for_count2 = 0;
        if (lastDay.getDay() > weekStand) {
          for_count2 = 6 - (lastDay.getDay() - weekStand);
        } else {
          for_count2 = weekStand - lastDay.getDay() - 1;
        }

        for (var j=0; j < for_count2; j++) {
          var insert_date = new Date(date.getFullYear(), date.getMonth(), 1);
          insert_date.setDate(lastDay.getDate() + j + 1);
          var weekObj3 = {};
          weekObj3.date = insert_date.getFullYear().toString() + (insert_date.getMonth() + 1).toString().padStart(2, "0") + insert_date.getDate().toString().padStart(2, "0");
          weekObj3.weekNum = weekNum;
          weekObjArray.push(weekObj3);
        }
      }

      this.week_date = weekObjArray;
      this.week_date.sort((a, b) => a.date.toLowerCase() < b.date.toLowerCase() ? -1 : 1);
      console.log( this.week_date );

      this.week_list = this.week_date.filter(
        (e, i, callback) =>
          i === callback.findIndex(
            (e1) => e1.weekNum === e.weekNum
          )
      );

      this.week_list.sort((a, b) => a.weekNum - b.weekNum);

      var temp_weekArr = [];
      for (var b=0; b < this.week_list.length; b++) {
        if (this.week_list[b].weekNum > 0) {
          var temp_weekArrData = { text: this.week_list[b].weekNum + "주", value: this.week_list[b].weekNum};
          temp_weekArr.push(temp_weekArrData);
        };
      };

      // 정산기간 주간 셀렉트박스 셋업
      this.searchWeekOptions = temp_weekArr;
      var week_str = [];
      this.week_date.forEach((e,i) => {
        if (e.date === date_str) {
          week_str.push(e);  
        }
      });

      if (this.filterData.month === 0) {
        this.filterData.month = month;  // 현재월 설정
        this.filterData.week = week_str[0].weekNum; // 현재주간 설정
      } else {
        this.filterData.week = 1; // 현재주간 설정
      }

      var dateArr = [];
      this.week_date.forEach((e,i) => {
        if (e.weekNum === this.filterData.week || e.weekNum === (this.filterData.week - 1) || e.weekNum === (this.filterData.week - 2)) {
          dateArr.push(e);  
        }
      });

      dateArr.sort((a, b) => a.date.toLowerCase() < b.date.toLowerCase() ? -1 : 1);

      this.chargingInfo(dateArr[0].date, dateArr[dateArr.length - 1].date);
    },
    changeWeek() {
      var dateArr = [];
      this.week_date.forEach((e,i) => {
        if (e.weekNum === this.filterData.week || e.weekNum === (this.filterData.week - 1) || e.weekNum === (this.filterData.week - 2)) {
          dateArr.push(e);  
        }
      });

      dateArr.sort((a, b) => a.date.toLowerCase() < b.date.toLowerCase() ? -1 : 1);

      this.chargingInfo(dateArr[0].date, dateArr[dateArr.length - 1].date);
    },
    async chargingInfo(sDate, eDate) {
      var bid = "CPS00002";
/*
      if (this.admin_data.company_id != "" && this.admin_data.company_id != "CPS00002") {
        bid = this.admin_data.company_id;
      } else if (this.admin_data.company_id === "CP00005" || this.admin_data.company_id === "") {
        bid = "CPS00002";
      };
*/
      try {
        const { data } = await this.$axios.post(
          "/api/selectDashboardChargingList", {
//          "/selectDashboardChargingList", {
            bid: bid,
            s_date: sDate,
            e_date: eDate
          }
        );
        if (data.length > 0) {
          this.charging_info = data;
          this.setData();
        }
      } catch (error) {
        
      }
    },
    setData() {
      // 충전기 종류에 따른 충전목록 필터링
      var temp_data = [];
      if (this.filterData.type == "all") {
        temp_data = this.charging_info
      } else if (this.filterData.type == "F") {
        this.charging_info.forEach((e,i) => {
          if (e.laf === "F") {
            temp_data.push(e);  
          }
        });
      } else if (this.filterData.type == "L") {
        this.charging_info.forEach((e,i) => {
          if (e.laf === "L") {
            temp_data.push(e);  
          }
        });
      };
      
      this.cp_top50_data = [];
      this.cp_capacity_data = [];
      this.dashboard_data = [];

      for (var a=0; a < temp_data.length; a++) {
        for (var b=0; b < this.week_date.length; b++) {
          if (temp_data[a].s_date.substr(0, 8) == this.week_date[b].date) {
            temp_data[a].week = this.week_date[b].weekNum
          };
        };

        // 충전기 용량별 구분정보 추가
        var capacity_value = parseInt(temp_data[a].code_name.replace("kW", ""));
        if (capacity_value <= 25) {
          temp_data[a].capa_type = "25k";
        } else if (25 < capacity_value && capacity_value <= 50) {
          temp_data[a].capa_type = "50k";
        } else if (50 < capacity_value && capacity_value <= 75) {
          temp_data[a].capa_type = "75k";
        } else if (75 < capacity_value && capacity_value <= 150) {
          temp_data[a].capa_type = "150k";
        } else if (150 < capacity_value) {
          temp_data[a].capa_type = "max";
        };
      };
      
      this.charging_list_temp = temp_data;

      for (var b=0; b < this.week_list.length; b++) {
        var weekData = [];
        this.charging_list_temp.forEach((e,i) => {
          if (e.week === this.week_list[b].weekNum) {
            weekData.push(e);  
          }
        });

        var charging_count = weekData.length;
        var charge_value = 0.0;
        var charge_amount = 0;
        var charge_user = 0;
        var v_25k = 0;
        var v_50k = 0;
        var v_75k = 0;
        var v_150k = 0;
        var v_max = 0;

        weekData.forEach((e,i) => {
          if (e.capa_type === "25k") {
            v_25k++;  
          } else if (e.capa_type === "50k") {
            v_50k++;
          } else if (e.capa_type === "75k") {
            v_75k++;
          } else if (e.capa_type === "150k") {
            v_150k++;
          } else if (e.capa_type === "max") {
            v_max++;
          }
        });

        var v_total = v_25k + v_50k + v_75k + v_150k + v_max;
        var cpCapacityObj = {"week": b-1, "v25k": v_25k, "v50k": v_50k, "v75k": v_75k, "v150k": v_150k, "max": v_max, "total": v_total};
        this.cp_capacity_data.push(cpCapacityObj);

        var mUser_count = 0;
        var nUser_count = 0;

        weekData.forEach((e,i) => {
          if (e.user_card === "9999999999999999") {
            nUser_count++;  
          }
        });

        var tempData = weekData.filter(
          (e, i, callback) =>
            i === callback.findIndex(
              (e1) => e1.user_card === e.user_card && e1.user_card !== "9999999999999999"
            )
        );

        mUser_count = tempData.length;

        var total_use_user = mUser_count + nUser_count;

        var cid_arr = weekData.filter(
          (e, i, callback) =>
            i === callback.findIndex(
              (e1) => e1.cid === e.cid && e1.sid === e.sid
            )
        );

        for (var c=0; c < cid_arr.length; c++) {
          var cid_temp_data = [];
          weekData.forEach((e,i) => {
            if (e.cid === cid_arr[c].cid && e.sid === cid_arr[c].sid) {
              this.cid_charging_data.push(e);
              cid_temp_data.push(e);
            }
          });
          
          var cid_mUser_count = 0;
          var cid_nUser_count = 0;

          cid_temp_data.forEach((e,i) => {
            if (e.user_card === "9999999999999999") {
              cid_nUser_count++;  
            }
          });

          var tempData2 = cid_temp_data.filter(
            (e, i, callback) =>
              i === callback.findIndex(
                (e1) => e1.user_card === e.user_card && e1.user_card !== "9999999999999999"
              )
          );

          cid_mUser_count = tempData2.length;

          var cid_total_use_user = cid_mUser_count + cid_nUser_count;
          
          var cid_value = 0.0;
          var cid_amount = 0;

          for (var d=0; d < cid_temp_data.length; d++) {
            cid_value = cid_value + Number(cid_temp_data[d].u_electricity);
            cid_amount = cid_amount + parseInt(cid_temp_data[d].charge);
          };
          
          var chargingObj = {"week": b-1, "sname": cid_temp_data[0].sname, "sid": cid_temp_data[0].sid, "cid": cid_temp_data[0].cid, "laf": cid_temp_data[0].laf, "charging_count": cid_temp_data.length, "charging_value": cid_value, "charging_amount": cid_amount, "charging_user": cid_total_use_user};
          this.cp_top50_data.push(chargingObj);

          charge_value = charge_value + cid_value;
          charge_amount = charge_amount + cid_amount;
        };
        var dashboardObj = {"week": b-1, "charger_num": cid_arr.length, "charging_count": charging_count, "charging_value": charge_value, "charging_amount": charge_amount, "charging_user": total_use_user};
        this.dashboard_data.push(dashboardObj);
      };

      //내림차순 정렬
      //this.dashboard_data.sort((a, b) => a.charging_value.toLowerCase() > b.charging_value.toLowerCase() ? -1 : 1);
      this.dashboard_data.sort((a, b) => b.charging_value - a.charging_value);

      this.setDashboard();		// 대시보드 페이지 표기
    },
    setDashboard() {
      var select_week = this.filterData.week;								// 현재 설정된 조회기간 값(주차)
      var total_charge_count = this.charge_count_data;				// 전체 충전기수 데이터

      // 주간 이용데이터 셋
      var line1_crt = [];
      var line1_b1 = [];
      var line1_b2 = [];
      var week_charging_data = [];

      this.dashboard_data.forEach((e,i) => {
        if (e.week === select_week) {
          line1_crt.push(e);
        } else if (e.week === (select_week - 1)) {
          line1_b1.push(e);
        } else if (e.week === (select_week - 2)) {
          line1_b2.push(e);
        }
      });

      this.charging_list_temp.forEach((e,i) => {
        if (e.week === select_week) {
          week_charging_data.push(e);  
        }
      });

      // 충전기 이용률
      var crt_use_per = line1_crt[0].charger_num*100/total_charge_count;	// 현재 충전기 이용률
      var b1_use_per = line1_b1[0].charger_num*100/total_charge_count;	// 1주전 충전기 이용률
      var b2_use_per = line1_b2[0].charger_num*100/total_charge_count;	// 2주전 충전기 이용률
      var per_diff = crt_use_per - b1_use_per;							// 전주 대비 이용률 차이

      // 충전기 주간 총 합계 라인 셋업
      // 충전기 이용률 셋업
      this.obj_per.main_str = crt_use_per.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "%";
      this.obj_per.sub_str = "(" + line1_crt[0].charger_num + "/" + total_charge_count + ")";
      this.obj_per.first_line = b1_use_per.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "% (" + line1_b1[0].charger_num + "/" + total_charge_count + ")";
      this.obj_per.second_line = b2_use_per.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "% (" + line1_b2[0].charger_num + "/" + total_charge_count + ")";
      if (per_diff < 0) {
        var tempPer = per_diff*-1
        this.obj_per.third_line = tempPer.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "% 감소";
        this.obj_per.third_type = "blue";
      } else {
        this.obj_per.third_line = per_diff.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "% 증가";
        this.obj_per.third_type = "red";
      }
      this.obj_per.data = week_charging_data;

      // 충전횟수 셋업
      this.obj_count.main_str = line1_crt[0].charging_count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_count.first_line = line1_b1[0].charging_count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_count.second_line = line1_b2[0].charging_count.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line1_crt[0].charging_count - line1_b1[0].charging_count) < 0) {
        var tempCount = (line1_crt[0].charging_count - line1_b1[0].charging_count)*-1;
        this.obj_count.third_line = tempCount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_count.third_type = "blue";
      } else {
        var tempCount = line1_crt[0].charging_count - line1_b1[0].charging_count;
        this.obj_count.third_line = tempCount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_count.third_type = "red";
      }
      this.obj_count.data = week_charging_data;

      // 충전량 셋업
      this.obj_value.main_str = line1_crt[0].charging_value.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_value.first_line = line1_b1[0].charging_value.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "kWh";
      this.obj_value.second_line = line1_b2[0].charging_value.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "kWh";
      if ((line1_crt[0].charging_value - line1_b1[0].charging_value) < 0) {
        var tempValue = (line1_crt[0].charging_value - line1_b1[0].charging_value)*-1;
        this.obj_value.third_line = tempValue.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "kWh 감소";
        this.obj_value.third_type = "blue";
      } else {
        var tempValue = line1_crt[0].charging_value - line1_b1[0].charging_value;
        this.obj_value.third_line = tempValue.toFixed(1).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "kWh 증가";
        this.obj_value.third_type = "red";
      }
      this.obj_value.data = week_charging_data;

      // 충전요금
      this.obj_amount.main_str = line1_crt[0].charging_amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_amount.first_line = line1_b1[0].charging_amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "원";
      this.obj_amount.second_line = line1_b2[0].charging_amount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "원";
      if ((line1_crt[0].charging_amount - line1_b1[0].charging_count) < 0) {
        var tempAmount = (line1_crt[0].charging_amount - line1_b1[0].charging_amount)*-1;
        this.obj_amount.third_line = tempAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "원 감소";
        this.obj_amount.third_type = "blue";
      } else {
        var tempAmount = line1_crt[0].charging_amount - line1_b1[0].charging_amount;
        this.obj_amount.third_line = tempAmount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "원 증가";
        this.obj_amount.third_type = "red";
      }
      this.obj_amount.data = week_charging_data;

      // 이용자 수 셋업
      this.obj_user.main_str = line1_crt[0].charging_user.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_user.first_line = line1_b1[0].charging_user.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "명";
      this.obj_user.second_line = line1_b2[0].charging_user.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "명";
      if ((line1_crt[0].charging_user - line1_b1[0].charging_user) < 0) {
        var tempUserCount = (line1_crt[0].charging_user - line1_b1[0].charging_user)*-1;
        this.obj_user.third_line = tempUserCount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "명 감소";
        this.obj_user.third_type = "blue";
      } else {
        var tempUserCount = line1_crt[0].charging_user - line1_b1[0].charging_user;
        this.obj_user.third_line = tempUserCount.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "명 증가";
        this.obj_user.third_type = "red";
      }
      this.obj_user.data = week_charging_data;

      // 충전기 용량별 사용 통계 라인 셋업
      var line2_crt = [];		// 현재 충전기 용량별 데이터 가져오기
      var line2_b1 = [];	// 1주전 충전기 용량별 데이터 가져오기
      var line2_b2 = [];	// 2주전 충전기 용량별 데이터 가져오기

      var charging_data_25k = [];
      var charging_data_50k = [];
      var charging_data_75k = [];
      var charging_data_150k = [];
      var charging_data_max = [];

      this.cp_capacity_data.forEach((e,i) => {
        if (e.week === select_week) {
          line2_crt.push(e);
        } else if (e.week === (select_week - 1)) {
          line2_b1.push(e);
        } else if (e.week === (select_week - 2)) {
          line2_b2.push(e);
        }
      });

      this.charging_list_temp.forEach((e,i) => {
        if (e.capa_type === "25k") {
          charging_data_25k.push(e);  
        } else if (e.capa_type === "50k") {
          charging_data_50k.push(e);  
        } else if (e.capa_type === "75k") {
          charging_data_75k.push(e);  
        } else if (e.capa_type === "150k") {
          charging_data_150k.push(e);  
        } else if (e.capa_type === "max") {
          charging_data_max.push(e);  
        }
      });

      var per_25k = 0;
      var per_50k = 0;
      var per_75k = 0;
      var per_150k = 0;
      var per_max = 0;

      // 충전기 용량별 비율
      if (line2_crt[0].total > 0) {
        per_25k = line2_crt[0].v25k*100/line2_crt[0].total;
        per_50k = line2_crt[0].v50k*100/line2_crt[0].total;
        per_75k = line2_crt[0].v75k*100/line2_crt[0].total;
        per_150k = line2_crt[0].v150k*100/line2_crt[0].total;
        per_max = line2_crt[0].max*100/line2_crt[0].total;
      };

      // 충전기 용량별 데이터 셋업
      // 충전기 용량 25W
      this.obj_25.title = "25kWh 이하(" + per_25k.toFixed(1) + "%)";
      this.obj_25.main_str = line2_crt[0].v25k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_25.first_line = line2_b1[0].v25k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_25.second_line = line2_b2[0].v25k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line2_crt[0].v25k - line2_b1[0].v25k) < 0) {
        var tempV25k = (line2_crt[0].v25k - line2_b1[0].v25k)*-1;
        this.obj_25.third_line = tempV25k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_25.third_type = "blue";
      } else {
        var tempV25k = line2_crt[0].v25k - line2_b1[0].v25k;
        this.obj_25.third_line = tempV25k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_25.third_type = "red";
      }
      this.obj_25.data = charging_data_25k;

      // 충전기 용량 50W
      this.obj_50.title = "25kWh ~ 50kWh(" + per_50k.toFixed(1) + "%)";
      this.obj_50.main_str = line2_crt[0].v50k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_50.first_line = line2_b1[0].v50k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_50.second_line = line2_b2[0].v50k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line2_crt[0].v50k - line2_b1[0].v50k) < 0) {
        var tempV50k = (line2_crt[0].v50k - line2_b1[0].v50k)*-1;
        this.obj_50.third_line = tempV50k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_50.third_type = "blue";
      } else {
        var tempV50k = line2_crt[0].v50k - line2_b1[0].v50k;
        this.obj_50.third_line = tempV50k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_50.third_type = "red";
      }
      this.obj_50.data = charging_data_50k;

      // 충전기 용량 75W
      this.obj_75.title = "50kWh ~ 75kWh(" + per_75k.toFixed(1) + "%)";
      this.obj_75.main_str = line2_crt[0].v75k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_75.first_line = line2_b1[0].v75k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_75.second_line = line2_b2[0].v75k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line2_crt[0].v75k - line2_b1[0].v75k) < 0) {
        var tempV75k = (line2_crt[0].v75k - line2_b1[0].v75k)*-1;
        this.obj_75.third_line = tempV75k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_75.third_type = "blue";
      } else {
        var tempV75k = line2_crt[0].v75k - line2_b1[0].v75k;
        this.obj_75.third_line = tempV75k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_75.third_type = "red";
      }
      this.obj_75.data = charging_data_75k;

      // 충전기 용량 150W
      this.obj_150.title = "75kWh ~ 150kWh(" + per_150k.toFixed(1) + "%)";
      this.obj_150.main_str = line2_crt[0].v150k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_150.first_line = line2_b1[0].v150k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_150.second_line = line2_b2[0].v150k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line2_crt[0].v150k - line2_b1[0].v150k) < 0) {
        var tempV150k = (line2_crt[0].v150k - line2_b1[0].v150k)*-1;
        this.obj_150.third_line = tempV150k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_150.third_type = "blue";
      } else {
        var tempV150k = line2_crt[0].v150k - line2_b1[0].v150k;
        this.obj_150.third_line = tempV150k.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_150.third_type = "red";
      }
      this.obj_150.data = charging_data_150k;

      // 충전기 용량 150w 이상
      this.obj_max.title = "150kWh 이상(" + per_max.toFixed(1) + "%)";
      this.obj_max.main_str = line2_crt[0].max.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      this.obj_max.first_line = line2_b1[0].max.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      this.obj_max.second_line = line2_b2[0].max.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회";
      if ((line2_crt[0].max - line2_b1[0].max) < 0) {
        var tempMax = (line2_crt[0].max - line2_b1[0].max)*-1;
        this.obj_max.third_line = tempMax.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 감소";
        this.obj_max.third_type = "blue";
      } else {
        var tempMax = line2_crt[0].max - line2_b1[0].max;
        this.obj_max.third_line = tempMax.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") + "회 증가";
        this.obj_max.third_type = "red";
      }
      this.obj_max.data = charging_data_max;

      // 충전기 top50 데이터 셋
      var top50_temp = [];
      this.cp_top50_data.forEach((e,i) => {
        if (e.week === select_week) {
          top50_temp.push(e);
        }
      });

      top50_temp.sort((a, b) => b.charging_value - a.charging_value);

      var top50_tempData = [];
      for (var i=0; i < top50_temp.length; i++) {
        if (i < 50) {
          top50_tempData.push(top50_temp[i]);
        };
      };
      this.gData = top50_tempData;
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "chargePoint_top50_" + this.filterData.type + "_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'chargePoint_top50_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>