<template>
  <b-modal
    id="modal-fw-add"
    title="펌웨어 등록"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />제조사
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="fwData.maker" :options="makerOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />모델ID
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="fwData.model_id" :options="modelOptions"></b-form-select>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />펌웨어버전
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input size="sm" v-model="fwData.fw_ver"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />url
            </span>
          </div>
          <div class="d-flex" style="gap: 10px; width: 100%;">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="file_name"></b-form-input>
            <div class="upload-btn" style="width: 106px;">
              <label for="file_input">
                <span class="ti ti-upload"> 파일검색</span>
              </label>
              <input id="file_input" type="file" style="display: none;" @change="readFile" accept=".zip">
            </div>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />변경내용
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-textarea
              id="note"
              v-model="fwData.descript"
              placeholder=""
              size="sm"
              style="border-radius: 10px;"
              rows="6"
              max-rows="6"
            ></b-form-textarea>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      maker_list: Object,
      param_data: Object,
    },
  },
  data() {
    return {
      file: null,
      file_name: "",
      fwData: {
        fw_ver: "",
        descript: "",
        url: "",
        reg_user: this.admin_data.user_id,
        reg_date: "",
        model_id: "",
        maker: "",
      },
      model_list: [],
      makerOptions: [],
      modelOptions: [],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.admin_data = this.$store.getters.user;
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let options = [];

        this.obj.maker_list.forEach((e,i) => {
          let add_option = { value: e.company_id, text: e.name }
          options.push(add_option);
        });

        this.makerOptions = options;
        this.fwData.maker = this.makerOptions[0].value;

        this.selectChargerModel();
      });
    },
    async selectChargerModel() {
      try {
        const { data } = await this.$axios.post(
          "/api/selectChargerModel", {
            //use_yn: "Y", 
            //type: "CPC"
          }
        );

        if (data.length > 0) {
          this.model_list = data;
          var modelData = [];
          var add_option = {};

          this.model_list.filter(
            function (e) {
              if (e.maker == this.fwData.maker) {
                add_option = { text: e.model_id, value: e.model_id };
                modelData.push(add_option);
              }
            }
          )

          this.modelOptions = modelData;
          this.fwData.model_id = this.modelOptions[0].value;
        }
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async ok() {
      try {
        var specialChars = /[*|\":<>[\]{}`\\()';@&$]/;

        if (this.fwData.fw_ver === "" || this.fwData.url === "" || this.fwData.descript === "") {
          window.alert("정보를 모두 입력하신 후 다시 시도해 주시기 바랍니다.");
        } else if (specialChars.test(this.file_name)) {
          window.alert("파일명에 특수문자가 포함되어 있습니다. 특수문자를 제거해주세요.");
        } else {
          this.addFw();

          window.alert("펌웨어 등록이 완료되었습니다.");
          var context = "펌웨어버전 : " + this.fwData.fw_ver;
          this.addAdminLog("펌웨어 관리", "펌웨어 등록", "펌웨어 등록팝업", context, this.admin_data.user_id);
          this.$emit("reflash");
          this.$bvModal.hide("modal-fw-add");
        }
      }  catch (error) {
        window.alert("펌웨어 등록을 실패했습니다.");
        console.log(error);
      }
    },
    async addFw() {
      let crt_date = this.getToday();

      let maker_data = {};
      this.obj.maker_list.forEach((e,i) => {
        if (e.company_id === this.fwData.maker) {
          maker_data = e;
        }
      });

      let url_str = "/var/lib/tomcat9/webapps/evpay_admin/ui/tps/fw/" + maker_data.ftpUrl + "/" + this.file_name;

      try {
        const { data } = await this.$axios.post(
          "/api/addFw", {
            fw_ver: this.fwData.fw_ver,
						descript: this.fwData.descript,
						url: url_str,
						reg_user: this.admin_data.user_id,
						reg_date: crt_date,
						model_id: this.fwData.model_id,
						maker: this.fwData.maker,
          }
        );

        this.fileUpload();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async fileUpload() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("관리자 명령 로그 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
