<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">종류</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchType" @change="filterSet" :options="searchTypeOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">계약상태</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchStatus" @change="filterSet" :options="searchStatusOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="filterSet"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
            
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6>
          <div class="d-flex justify-content-end">
            <b-button class="btn_def" @click="detail('add')">펌웨어 관리</b-button>
            <b-button class="btn_def" @click="detail('add')">등록</b-button>
            <b-button class="btn_def" @click="detail('edit')">수정</b-button>
            <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
          <!--
            <b-button class="btn_def" @click="UploadExcel">엑셀업로드</b-button>
            <b-button class="btn_def" href="http://localhost:6060/charger_model_sampleDownload?filename='charger_model_sample.xlsx'" download="charger_sample.xlsx" @click="SampleDown">샘플다운</b-button>
          -->
          </div>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2"  style="width: -webkit-fill-available;">
          <b-table 
            id = "grid"
            bordered hover noCollapse
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(company_id)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].company_id }}
              </div>
            </template>
            <template #cell(type_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].type_name }}
              </div>
            </template>
            <template #cell(name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].name }}
              </div>
            </template>
            <template #cell(manager_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].manager_name }}
              </div>
            </template>
            <template #cell(phone)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].phone }}
              </div>
            </template>
            <template #cell(email)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].email }}
              </div>
            </template>
            <template #cell(addr)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].addr + " " + gData[row.index].addr_dtl }}
              </div>
            </template>
            <template #cell(reg_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].reg_date }}
              </div>
            </template>
            <template #cell(status)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status_name }}
              </div>
            </template>
            <template #cell(detail_call)>
              <b-button size="sm" @click="detail('detail')" class="btn_def">상세정보</b-button>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
      <!--<modal-excel-upload :file_type="file_type"/>-->
      <modal-company-detail ref="modal_companyDtl" :obj="obj" />
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    //ModalExcelUpload: Modals.ExcelUpload,
    ModalCompanyDetail: Modals.CompanyDetail,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        company_id : "",
        name : "",
        type : "",
        phone : "",
        manager_name : "",
        addr : "",
        addr_dtl : "",
        gps : "",
        email : "",
        bank_code : "",
        bank_name : "",
        account_no : "",
        reg_date : "",
        update_date : "",
        use_yn : "",
        certification_url : "",
        account_type : "",
        account_day : "",
        status : "",
        type_name : "",
        status_name : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "company_id",
          label: "법인 ID"
        },
        {
          key: "type_name",
          label: "종류"
        },
        {
          key: "name",
          label: "법인명"
        },
        {
          key: "manager_name",
          label: "담당자 이름"
        },
        {
          key: "phone",
          label: "연락처"
        },
        {
          key: "email",
          label: "이메일"
        },
        {
          key: "addr",
          label: "사업장 주소"
        },
        {
          key: "reg_date",
          label: "계약일자"
        },
        {
          key: "status",
          label: "계약상태"
        },
        {
          key: "detail_call",
          label: "상세보기"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchType: "all",
        searchStatus: "all",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "법인ID", value: "company_id" },
        { text: "법인명", value: "name" }
      ],
      searchTypeOption: [
        { text: "전체", value: "all" },
        { text: "설치법인", value: "I" },
        { text: "AS법인", value: "A" },
        { text: "서비스법인", value: "S" },
        { text: "제조사", value: "M" }
      ],
      searchStatusOption: [
        { text: "전체", value: "all" },
        { text: "계약중", value: "S" },
        { text: "계약종료", value: "E" },
        { text: "계약대기", value: "W" },
        { text: "계약취소", value: "C" }
      ],
      file_type: "company_list",
      rules: {
        
      },
      excelUp_show: false,
      tranHistory_show: false,
      obj: {
        edit_type: "detail",
        title: "회원 상세정보",
        ok_title: "확인",
        company_data: {
          company_id : "",
          name : "",
          type : "",
          phone : "",
          manager_name : "",
          addr : "",
          addr_dtl : "",
          gps : "",
          email : "",
          bank_code : "",
          bank_name : "",
          account_no : "",
          reg_date : "",
          update_date : "",
          use_yn : "",
          certification_url : "",
          account_type : "",
          account_day : "",
          status : "",
          type_name : "",
          status_name : "",
        }
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectCompanyList() {
      console.log("업체정보 관리 페이지");
      try {
        const { data } = await this.$axios.post(
          "/api/selectAdminCompany", {
            //use_yn : "Y",
            //type : "C"
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    detail(type) {
      if (type === "add") {
        this.obj.edit_type = type;
        this.obj.title = "업체정보 등록";
        this.obj.ok_title = "등록";
        this.obj.company_data = { 
          company_id : "",
          name : "",
          type : "",
          phone : "",
          manager_name : "",
          addr : "",
          addr_dtl : "",
          gps : "",
          email : "",
          bank_code : "",
          bank_name : "",
          account_no : "",
          reg_date : "",
          update_date : "",
          use_yn : "",
          certification_url : "",
          account_type : "",
          account_day : "",
          status : "",
          type_name : "",
          status_name : "",
        };
        this.$refs.modal_companyDtl.setData(this.obj.company_data);
        this.$bvModal.show("modal-company-detail");
      } else {
        if (this.select_index > -1) { 
          if (type === "edit") {
            this.obj.edit_type = type;
            this.obj.title = "업체정보 수정";
            this.obj.ok_title = "수정";
            this.obj.company_data = this.select_rowData;
          } else if (type === "detail") {
            this.obj.edit_type = type;
            this.obj.title = "업체 상세정보";
            this.obj.ok_title = "닫기";
            this.obj.company_data = this.select_rowData;
          }

          this.$refs.modal_companyDtl.setData(this.select_rowData);
          this.$bvModal.show("modal-company-detail");
        } else if (this.select_index == -1) {
          window.alert("업체를 선택하신 후 다시 시도해 주시기 바랍니다.");
        }
      }
    },
    delCompany() {
      this.obj.title = "업체정보 삭제";
      this.obj.ok_title = "삭제";
      this.obj.company_data = this.select_rowData;
      this.$bvModal.show("modal-company-detail");
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "company_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'comapny_list'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    UploadExcel() {
      this.file_type = "company_list";
      this.$bvModal.show("modal-excel-upload");
    },
    async SampleDown() {
      try {
        const response = await this.$axios.post(
          "/api/SampleDownload", {
            filename: "comapny_sample",
            responseType: "blob"
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "company_sample.xlsx");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchType;
      let search_status = this.filterData.searchStatus;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.company_id.indexOf(search_key) != -1 || e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "company_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.company_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "name") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_status != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.status.indexOf(search_status) != -1) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      if (search_type != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.type.indexOf(search_type) != -1) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          
          this.select_rowData = {};

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 130;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;
    
    this.selectCompanyList();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>