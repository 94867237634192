<template>
  <div class="" style="height: inherit;" ref="element" v-resize @resize="onResize">
    <b-container>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">종류</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchType" @change="filterSet" :options="searchTypeOption"></b-form-select>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">상태</span>
          <b-form-select size="sm" style="flex: 4;" v-model="filterData.searchStatus" @change="filterSet" :options="searchStatusOption"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          <span class="d-flex justify-content-center align-items-center" style="flex: 1; font-weight: 700;">검색조건</span>
          <div class="d-flex align-items-center" style="gap: 10px; flex: 4; padding: 5px;">
            <b-form-select size="sm" style="flex: 1;" v-model="filterData.searchKeyType" @change="filterSet" :options="searchKeyOption"></b-form-select>
            <b-form-input 
              size="sm"
              v-model="filterData.searchKey"
              style="flex: 3; height: 50px; border-radius: 10px;"
              @change="filterSet"
            ></b-form-input>
          </div>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-center flex-row align-items-center">
          
        </b-col>
      </b-row>
      <b-row style="margin: 0px 0px 10px 0px;">
        <b-col lg=6 class="d-flex justify-content-between flex-row align-items-center">
          <span>전체 {{ gridFilterData.length }} 건</span>
        </b-col>
        <b-col lg=6 class="d-flex justify-content-end">
          <b-button class="btn_def" @click="DownloadExcel">엑셀다운</b-button>
        </b-col>
      </b-row>
      <b-row style="width: inherit; margin: 0px;" :style="{'height': page_grid_height + 'px'}">
        <div class="table-wrapper2" style="width: inherit;" >
          <b-table 
            id = "grid"
            bordered hover noCollapse 
            :items="gData"
            :fields="gridFields"
            small
          >
            <template #cell(No)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ row.index + 1 + ((currentPage - 1) * perPage) }}
              </div>
            </template>
            <template #cell(code_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].code_name }}
              </div>
            </template>
            <template #cell(req_user)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].req_user }}
              </div>
            </template>
            <template #cell(name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].name }}
              </div>
            </template>
            <template #cell(title)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].title }}
              </div>
            </template>
            <template #cell(descript)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].descript }}
              </div>
            </template>
            <template #cell(req_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].req_date }}
              </div>
            </template>
            <template #cell(f_date)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].f_date }}
              </div>
            </template>
            <template #cell(status_name)="row">
              <div style="cursor: pointer;" @dblclick="clipboardCopy(row.index)" @click="selectRow(row.index)">
                {{ gData[row.index].status_name }}
              </div>
            </template>
          </b-table>
        </div>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="gridFilterData.length"
          :per-page="perPage"
          first-text="⏮"
          prev-text="⏪"
          next-text="⏩"
          last-text="⏭"
          class="mt-4"
          size="sm"
        ></b-pagination>
      </b-row>

      <!-- modals -->
    </b-container>
  </div>
</template>

<script>
import Form from "@/components/Form/index";
import regex from "@/lib/regex";

import Modals from "@/components/Modals/index";
import { ref } from 'vue';

import * as XLSX from 'xlsx' // 라이브러리 import

import $ from 'jquery';

export default {
  components: {
    
    // Modals
    //ModalExcelUpload: Modals.ExcelUpload,
  },
  data() {
    const isDev = process.env.NODE_ENV !== "production";

    return {
      formValid: false,
      salt: 0,
      crt_width: 0,
      crt_height: 0,
      page_grid_height: 0,
      select_index: -1,
      select_rowData: {
        issue_id : "",
        code : "",
        code_name : "",
        req_type : "",
        type : "",
        title : "",
        descript : "",
        sid : "",
        station_name : "",
        cid : "",
        answer : "",
        as_id : "",
        as_name : "",
        req_user : "",
        name : "",
        phone : "",
        company_name : "",
        req_date : "",
        manager : "",
        rsv_date : "",
        f_date : "",
        status : "",
        call_no : "",
        s_call_date : "",
        e_call_date : "",
        remote_status : "",
        remote_date : "",
        req_type_name : "",
        status_name : "",
        zcode : "",
        zsgcode : "",
        addr : "",
        addr_dtl : "",
        gps : "",
        card_no : "",
      },
      perPage: 1,
      currentPage: 1,
      gridDefaultData: [

      ],
      gridFilterData: [

      ],
      gData: [

      ],
      gridFields: [
        {
          key: "No",
          label: "No"
        },
        {
          key: "code_name",
          label: "종류"
        },
        {
          key: "req_user",
          label: "회원 ID"
        },
        {
          key: "name",
          label: "회원 이름"
        },
        {
          key: "title",
          label: "제목"
        },
        {
          key: "descript",
          label: "내용"
        },
        {
          key: "req_date",
          label: "등록일시"
        },
        {
          key: "f_date",
          label: "처리일시"
        },
        {
          key: "status_name",
          label: "상태"
        },
      ],
      filterData: {
        searchKeyType: "all",
        searchKey: "",
        searchType: "all",
        searchStatus: "all",
      },
      searchKeyOption: [
        { text: "전체", value: "all" },
        { text: "회원ID", value: "user_id" },
        { text: "회원이름", value: "title" },
        { text: "제목", value: "title" },
        { text: "내용", value: "context" },
        { text: "제목 + 내용", value: "tnc" }
      ],
      searchTypeOption: [
        { text: "전체", value: "all" },
        { text: "실시간문의", value: "R" },
        { text: "상담문의", value: "C" },
        { text: "일반문의", value: "G" },
      ],
      searchStatusOption: [
        { text: "전체", value: "all" },
        { text: "접수", value: "CS01" },
        { text: "처리대기", value: "CS02" },
        { text: "처리예약", value: "CS03" },
        { text: "처리완료", value: "CS04" },
        { text: "미수신", value: "CS05" },
      ],
      file_type: "issue_list",
      rules: {
        
      },
    };
  },
  computed: {
    
  },
  watch: {
    currentPage: function(newVal, oldVal) {
      let items = this.gridFilterData;

      this.selectPage(newVal);
    },
  },
  methods: {
    async selectIssue() {
      console.log("메세지 발송내역 페이지");

      try {
        const { data } = await this.$axios.post(
          "/api/selectIssue", {
            //tsdt : s_date_str.replaceAll("-", ""),
            //tedt : e_date_str.replaceAll("-", ""),
          }
        );
        if (data.length > 0) {
          this.gData = data;
          this.gridDefaultData = data;
          this.filterSet();
        } else if (data.length == 0) {
          this.gData = null;
          window.alert("데이터가 없습니다.");
        }

        this.pageSet();
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    DownloadExcel() {
      var crt_date = this.getToday();
      var excel_name = "issue_list_" + crt_date;
      var workBook = XLSX.utils.book_new(); // 새로운 workbook 생성 
      var grid_list = XLSX.utils.json_to_sheet(this.gridFilterData);
      // var stdData = XLSX.utils.json_to_sheet(this.checkStdList); // json 객체를 sheet로 변환하여 워크시트 생성
      
      XLSX.utils.book_append_sheet(workBook, grid_list, 'issue_list_'); // stdData 워크시트로 시트 생성, 해당 시트 이름 명명
      // XLSX.utils.book_append_sheet(workBook, uncheckData, '학생현황'); // 시트 추가
      XLSX.writeFile(workBook, excel_name + '.xlsx'); // 파일 생성;
    },
    clipboardCopy(index) {
      var copy_data = JSON.stringify(this.gData[index]);
      window.navigator.clipboard.writeText(copy_data).then( function (res) {alert("복사되었습니다.");} );
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
    filterSet() {
      let search_key = this.filterData.searchKey;
      let search_type = this.filterData.searchType;
      let search_status = this.filterData.searchStatus;
      let temp_data = [];

      //this.gridDefaultData.

      if (search_key != "") {
        if (search_type === "all") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1 || e.message.indexOf(search_key) != -1 || e.user_id.indexOf(search_key) != -1 || e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "title") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "context") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.message.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "tnc") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.title.indexOf(search_key) != -1 || e.message.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "user_id") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.user_id.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        } else if (search_type === "name") {
          temp_data = this.gridDefaultData.filter(
            function (e) {
              if (e.name.indexOf(search_key) != -1) {
                return true;
              }
            }
          )
        }

        this.gridFilterData = temp_data;
      } else {
        this.gridFilterData = this.gridDefaultData;
      }

      if (search_type != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.req_type === search_type) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      if (search_status != "all") {
        temp_data = this.gridFilterData.filter(
          function (e) {
            if (e.status === search_status) {
              return true;
            }
          }
        )

        this.gridFilterData = temp_data;
      }

      this.pageSet();
    },
    selectRow(row_index) {
      var tbody = document.querySelector("#grid");
      var trs = tbody.getElementsByTagName('tr');
      
      var selectIndex = this.select_index + 1;

      if (this.select_index == -1) {
        this.select_index = row_index;
        this.select_rowData = this.gData[row_index];

        trs[row_index + 1].style.backgroundColor = "yellow";
      } else if (this.select_index > -1) {
        if (this.select_index === row_index) {
          this.select_index = -1;
          
          this.select_rowData = {};

          trs[selectIndex].style.backgroundColor = "white";
        } else {
          this.select_index = row_index;
          this.select_rowData = this.gData[row_index];

          trs[selectIndex].style.backgroundColor = "white";
          trs[row_index + 1].style.backgroundColor = "yellow";
        }
      }
    },
    onResize(e) {
      if (this.crt_width == 0 & this.crt_height == 0) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        //this.pageSet();
      } else if (this.crt_width != e.detail.width || this.crt_height != e.detail.height) {
        this.crt_width = e.detail.width;
        this.crt_height = e.detail.height;
        
        this.pageSet();  
      }
    },
    pageSet() {
      var filter_height = 130;
      var button_height = 60;
      var pagination_height = 50;
      var grid_header_height = 60;
      var grid_row_height = 65;
      var grid_data_height = this.crt_height - filter_height - button_height - pagination_height - grid_header_height - 40;
      this.page_grid_height = grid_data_height;

      var page_row_count = Math.floor(grid_data_height / grid_row_height);
      var max_page_num = Math.ceil(this.gridFilterData.length / page_row_count);

      this.perPage = page_row_count;

      this.selectPage(1);
    },
    selectPage(page) {
      let temp_grid_data = this.gridFilterData;

      this.gData = temp_grid_data.slice(
        (page - 1) * this.perPage, page * this.perPage
      )
    },
  },
  beforeDestroy() {
    //clearInterval(this.timerActive);
  },
  async mounted() {
    let page_data = this.$refs.element;
    this.crt_width = page_data.clientWidth;
    this.crt_height = page_data.clientHeight;
    
    this.selectIssue();
  },
};
</script>

<style lang="scss" scoped>
.selected {
  background-color: goldenrod;
}
</style>