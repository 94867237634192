<template>
  <b-modal
    id="modal-code-add"
    title="코드 추가"
    ok-title="등록"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div>
        <b-tabs v-model="tabIndex" pills card>
          <b-tab title="신규코드" active @click="changeTab">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />타입 코드
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.type"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />타입 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.type_name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />코드 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.code_name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />경로 (이미지)
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.url"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />추가 정보
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.descript"></b-form-input>
                </div>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="상위코드" @click="changeTab">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />코드 분류
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="codeData.type" :options="typeOptions" @change="changeType"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />코드 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.code_name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />경로 (이미지)
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.url"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />추가 정보
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.descript"></b-form-input>
                </div>
              </b-row>
            </div>
          </b-tab>
          <b-tab title="하위코드" @click="changeTab">
            <div class="mt-1">
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />코드 분류
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="codeData.type" :options="typeOptions" @change="changeType($event)"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />상위 코드
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-select size="sm" v-model="codeData.r_code" :options="rcodeOptions" @change="changeRcode($event)"></b-form-select>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />코드 이름
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.code_name"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />경로 (이미지)
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.url"></b-form-input>
                </div>
              </b-row>
              <b-row class="modal-input-row">
                <div class="modal-input-title">
                  <span class="text-17">
                    <font-awesome-icon />추가 정보
                  </span>
                </div>
                <div class="modal-input-content">
                  <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.descript"></b-form-input>
                </div>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      type_data: Object,
      first_code: Object,
      second_code: Object,
      code_data: Object,
    },
  },
  data() {
    return {
      tabIndex: 1,
      codeData: {
        type: "",
        type_name: "",
        code: "",
        code_name: "",
        level: 0,
        order_no: "",
        r_code: "",
        url: "",
        reg_date: "",
        reg_user: "",
        update_date: "",
        update_user: "",
        use_yn: "",
        descript: "",
      },
      typeOptions: [],
      rcodeOptions: [],
      first_code: [],
      second_code: [],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.admin_data = this.$store.getters.user;
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.typeOptions = this.obj.type_data;

        var first_options = [];
        var f_data = [];
        var second_options = [];

        this.obj.first_code.forEach((e,i) => {
          if (e.type === this.typeOptions[0].value) {
            var add_data = { text: e.code_name, value: e.code };
            first_options.push(add_data);
            f_data.push(e);
          }
        });

        this.rcodeOptions = first_options;
        this.first_code = f_data;

        this.obj.second_code.forEach((e,i) => {
          if (e.type === this.typeOptions[0].value && e.r_code === this.rcodeOptions[0].value) {
            second_options.push(e);
          }
        });

        this.second_code = second_options;

        this.codeData = this.obj.code_data;
        this.codeData.type = this.typeOptions[0].value;
        this.codeData.r_code = this.rcodeOptions[0].value;
      });
    },
    changeType(select_type) {
      var first_options = [];
      var f_data = [];

      this.obj.first_code.forEach((e,i) => {
        if (e.type === select_type) {
          var add_data = { text: e.code_name, value: e.code };
          first_options.push(add_data);
          f_data.push(e);
        }
      });

      this.rcodeOptions = first_options;
      this.first_code = f_data;
    },
    changeRcode(select_rcode) {
      var second_options = [];

      this.obj.second_code.forEach((e,i) => {
        if (e.type === this.codeData.type && e.r_code === select_rcode) {
          second_options.push(e);
        }
      });

      this.second_code = second_options;
    },
    async ok() {
      try {
        this.addCode();

        window.alert("코드가 등록되었습니다.");

        var level_str = "";
        if (this.codeData.level == 1) {
          level_str = "신규코드";
        } else if (this.codeData.level == 2) {
          level_str = "상위코드";
        } else if (this.codeData.level == 3) {
          level_str = "하위코드";
        }

        var context = "코드 ID : " + this.codeData.code + ", 코드 이름 : " + this.codeData.code_name + ", 코드 경로 : " + this.codeData.url + " , 코드 타입 : " + this.codeData.type_name + " , 코드 종류 : " + level_str;
        this.addAdminLog("코드 관리", "코드 등록", "코드 등록 팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-code-add");
      }  catch (error) {
        window.alert("코드 등록에 실패했습니다.");
        console.log(error);
      }
    },
    async addCode() {
      let crt_date = this.getToday();
      var type_code = "";
      var type_name = "";
      var code_id = "";
      var code_no = 0;
      var r_code = "";
      var level_value = 0;

      if (this.tabIndex === 1) {
        type_code = this.codeData.type;
        type_name = this.codeData.type_name;
        code_id = type_code + "01";
        code_no = 1;
        level_value = 1;
      } else {
        type_code = this.codeData.type;
        type_name = this.codeData.type_name;

        if (this.tabIndex === 2) {
          r_code = "";
          code_no = this.first_code.length + 1;
          var max_regNo = '00' + String(code_no);
          var mod_regNo = max_regNo.slice(-2);
          code_id = type_code + mod_regNo;
          level_value = 1;
        } else if (this.tabIndex === 3) {
          r_code = this.codeData.r_code;
          code_no = this.second_code.length + 1;
          var max_regNo = '00' + String(code_no);
          var mod_regNo = max_regNo.slice(-2);
          code_id = r_code + mod_regNo;
          level_value = 2;
        };
      };

      try {
        const { data } = await this.$axios.post(
          "/api/addCode", {
            type: type_code,
            type_name: type_name,
            code: code_id,
            code_name: this.codeData.code_name,
            level: level_value,
            order_no: parseInt(code_no),
            r_code: r_code,
            url: this.codeData.url,
            reg_date: crt_date,
            reg_user: this.admin_data.user_id,
            update_date: crt_date,
            update_user: this.admin_data.user_id,
            use_yn: "Y",
            descript: this.codeData.descript,
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("코드 등록을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
