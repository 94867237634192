<template>
  <b-modal
    id="modal-code-edit"
    title="코드 수정"
    ok-title="수정"
    @ok="ok"
    ok-only
    footer-class="w-100 d-flex justify-content-between p-1 border-0 btn-group flex-nowrap"
    ok-variant="main-color m-0 ml-1 py-3 w-50 btn-block"
    cancel-variant="outline-primary m-0 py-3 w-50 btn-block"
    header-class="border-0 text-lg-16 text-uppercase fw-700 text-center position-relative"
    header-close-content="<i class='icon icon-cancel text-18'></i>"
    header-close-variant="text text-gray position-absolute modal-close"
    :hideHeader-close="false"
    titleClass="mx-auto text-center text-16 text-lg-17"
    headerTextVariant="white text-lg-16"
    contentClass="confirm border-0 fw-400"
    :centered="true"
    :noCloseOnBackdrop="true"
    :noStacking="true"
    size="lg"
  >
    <div
      class="pr-md-2 overflow-auto"
      :style="{ maxWidth: '800px', maxHeight: '1000px' }"
    >
      <div v-if="obj.code_data.level === 1" class="mt-1">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />타입 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.type_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.code_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 경로(이미지)
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.url"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 설명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.descript"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />사용여부
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="codeData.use_yn" :options="useOptions"></b-form-select>
          </div>
        </b-row>
      </div>
      <div v-else-if="obj.code_data.level === 2" class="mt-1">
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />타입 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.type_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />상위 코드 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.r_code_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 이름
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.code_name"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 경로(이미지)
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.url"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />코드 설명
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-input style="height: 50px; border-radius: 10px;" v-model="codeData.descript"></b-form-input>
          </div>
        </b-row>
        <b-row class="modal-input-row">
          <div class="modal-input-title">
            <span class="text-17">
              <font-awesome-icon />사용여부
            </span>
          </div>
          <div class="modal-input-content">
            <b-form-select size="sm" v-model="codeData.use_yn" :options="useOptions"></b-form-select>
          </div>
        </b-row>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {

  },
  computed: {
    
  },
  watch: {
    obj() {
      console.log("체크");
    },
  },
  setup(props, { emit }) {
    watch(
      () => props.obj,
      (obj) => {
        console.log("체크");
      }
    )
  },
  props: {
    obj: {
      type_data: Object,
      first_code: Object,
      second_code: Object,
      code_data: Object,
    },
  },
  data() {
    return {
      tabIndex: 1,
      codeData: {
        type: "",
        type_name: "",
        code: "",
        code_name: "",
        level: 0,
        level_str: "",
        order_no: "",
        r_code: "",
        r_code_name: "",
        url: "",
        reg_date: "",
        reg_user: "",
        update_date: "",
        update_user: "",
        use_yn: "",
        descript: "",
      },
      useOptions: [
        { value: "Y", text: "사용" },
        { value: "N", text: "미사용" },
      ],
      admin_data: {},
      rules: {
        address: [
          (v) =>
            !!v || {
              title: "주소 입력",
              message: "주소를 입력하세요.",
            },
        ],
        // addressDetail: [
        //   (v) =>
        //     !!v || {
        //       title: "상세 주소 입력",
        //       message: "상세 주소를 입력하세요.",
        //     },
        // ],
      },
    };
  },
  mounted() {
    this.admin_data = this.$store.getters.user;
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.codeData = this.obj.code_data;
      });
    },
    async ok() {
      try {
        this.updateCode();

        window.alert("코드 정보가 수정되었습니다.");
        var context = "코드 ID : " + this.codeData.code + ", 코드 이름 : " + this.codeData.code_name + ", 코드 경로 : " + this.codeData.url + " , 코드 타입 : " + this.codeData.type_name + " , 코드 종류 : " + this.codeData.level_str;
        this.addAdminLog("코드 관리", "코드 수정", "코드 수정 팝업", context, this.admin_data.user_id);
        this.$emit("reflash");
        this.$bvModal.hide("modal-code-edit");
      }  catch (error) {
        window.alert("코드 정보가 수정을 실패했습니다.");
        console.log(error);
      }
    },
    setData(select_rowData) {
      this.codeData = select_rowData;
    },
    async updateCode() {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/updateCode", {
            type_name: this.codeData.type_name,
            code_name: this.codeData.code_name,
            order_no: parseInt(this.codeData.order_no),
            url: this.codeData.url,
            update_user: this.admin_data.user_id,
            update_date: crt_date,
            use_yn: this.codeData.use_yn,
            descript: this.codeData.descript, 
            code: this.codeData.code,
            type: this.codeData.type,
          }
        );
      } catch (error) {
        // console.log(7);
        console.log(error);
      }
    },
    async addAdminLog(type, type_dtl, page, content, reg_user) {
      let crt_date = this.getToday();

      try {
        const { data } = await this.$axios.post(
          "/api/addAdminUseListLog", {
            type: type,
						type_dtl: type_dtl,
						page: page,
						content: content,
						reg_user: reg_user,
						reg_date: crt_date,
          }
        );
      } catch (error) {
        window.alert("코드 정보 수정을 실패했습니다.");
      }
    },
    getToday() {
      let date = new Date();

      let year = date.getFullYear();
      let month = date.getMonth()+1 < 10 ? '0' + date.getMonth()+1 : date.getMonth()+1;
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      let crt_date = year.toString() + month.toString() + day.toString();

      let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours(); // 시
      let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();  // 분
      let seconds = date.getSeconds() < 10 ? '0' + date.getHours() : date.getHours();  // 초

      let crt_time = hours.toString() + minutes.toString() + seconds.toString();

      return crt_date + crt_time;
    },
  },
};
</script>

<style lang="scss" scoped></style>
